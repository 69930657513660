import { WorkOrderConstants } from "./WorkOrderConstants";

export const MIN_SCREEN_WIDTH: number = 992;
export const REMOVE_JOB_ITEM: string = "REMOVE_JOB_ITEM";
export const DEFAULT_TIME = "0:0";
export const DEFAULT_YEAR = 1970;
export const ENTER_KEY_CODE = "Enter";
export const INCREMENT_BY_ONE = "INCREMENT_BY_ONE";
export const DECREMENT_BY_ONE = "DECREMENT_BY_ONE";
export const LAST_PAGE = "LAST_PAGE";
export const FIRST_PAGE = "FIRST_PAGE";
export const SPECIFIC_PAGE = "SPECIFIC_PAGE";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const ADD_ITEM = "ADD_ITEM";

export const DOTS = -1;
export const FULL_MONTH_DATE_YEAR_H_M_FORMAT = "MMMM D,YYYY hh:mm a";
export const FULL_MONTH_DATE_YEAR = "MMMM D,YYYY";
export const TWELVE_H_M_FORMAT = "hh:mm a";
export const DEBOUNCE_DELAY: number = 600;

export const PAGE_SIZE = 20;
export const DEFAULT_DATE = "1111";
const TWENTY_HOURS = "1";
const SEVEN_DAYS = "7";
const FOURTEEN_DAYS = "14";
const THIRTY_DAYS = "30";
const SIXTY_DAYS = "60";

export const TOAST_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  CLOSED: "CLOSED",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  WARN: "WARN",
  CANCELED: "CANCELED",
};

export const DateRanges = [
  {
    id: DEFAULT_DATE,
    name: "All",
  },
  {
    id: TWENTY_HOURS,
    name: "24 Hours",
  },
  {
    id: SEVEN_DAYS,
    name: "7 Days",
  },
  {
    id: FOURTEEN_DAYS,
    name: "14 Days",
  },
  {
    id: THIRTY_DAYS,
    name: "30 Days",
  },
  {
    id: SIXTY_DAYS,
    name: "60 Days",
  },
];

export const StatusFiltersCategories = [
  {
    id: WorkOrderConstants.ASSIGNED,
    name: "Not Started",
  },
  {
    id: WorkOrderConstants.IN_PROGRESS,
    name: "In Progress",
  },
  {
    id: WorkOrderConstants.CANCELLED,
    name: "Cancel",
  },
  {
    id: WorkOrderConstants.CLOSED,
    name: "Close",
  },
  {
    id: WorkOrderConstants.DONE,
    name: "Complete",
  },
];

export const OpenWoFilters = [
  {
    id: WorkOrderConstants.ASSIGNED,
    name: "Not Started",
  },
  {
    id: WorkOrderConstants.IN_PROGRESS,
    name: "In Progress",
  },
  {
    id: WorkOrderConstants.ON_HOLD,
    name: "On Hold",
  },
];

export const ClosedWoFilters = [
  {
    id: WorkOrderConstants.CANCELLED,
    name: "Canceled",
  },
  {
    id: WorkOrderConstants.CLOSED,
    name: "Closed",
  },
  {
    id: WorkOrderConstants.DONE,
    name: "Completed",
  },
];
