export const LOGIN_LIST_ROLES: string = `/login/list-roles`;
export const CHECK_USER: string = `/login/check-user`;
export const GET_CATEGORIES: string = `/work-orders/categories`;
export const GET_PROPERTIES: string = `/users/properties`;
export const GET_PROPERTIES_UNITS: string = `/properties/:propertyId/units`;
export const FIND_JOBS: string = `/work-orders/findJobs`;
export const GET_PRO_USERS: string = `/real-estate/list-exclusive-pros`;
export const POST_PRO_USERS: string = `/property/pro-users`;
export const GET_JOB_ITEM: string = `/work-orders/jobs`;
export const UPDATE_JOB_ITEM: string = `/work-orders/jobs`;
export const JOB_ACTION: string = `work-orders/job-update-progress`;
export const FIND_JOBS_PRO: string = `/work-orders/findJobs`;
export const CREATE_JOB: string = `work-orders/job`;
export const MEDIA_ASSET: string = `media-assets/asset`;
export const DELETE_ASSET: string = `media-assets/asset/delete`;
export const USER_ALL_NOTIFICATION_PREFERENCES: string = `notification/user-all-preferences`;
export const FIND_ACTIVITY_HISTORY = `/activity-history/job`;
export const FIND_ACTIVITY_HISTORY_PROPERTY = `/activity-history/property`;
export const REMOVE_WORK_ORDER_PROOF = `/work-orders/job/:jobId/remove-proof/:assetId`;
export const LOGIN: string = `/auth/login`;
export const USER_INFORMATION: string = `/users/login-info`;
