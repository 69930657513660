import moment from "moment/moment";
import {
  JobStatusMap,
  RESMAN_APPOINTMENT_CONSTANT,
  ResManPriorityMap,
  WorkOrderConstants,
  MAINTENANCE_RESPROP,
} from "../../constants/WorkOrderConstants";
import {
  compareTwoDates,
  compareTwoDatesTime,
  findInArray,
  stringToDate,
} from "../utility";
import consoleLog from "../consoleLog";
import { DEFAULT_TIME, DEFAULT_YEAR } from "../../constants/GlobalConstants";
import {
  Category,
  DateCategory,
  FilterStatus,
  Job,
  JobItemReqBody,
  ProUserProp,
} from "../../models/kanabn";
import {
  REGULAR_CIRCLE_PAUSE,
  SOLID_ARROW_RIGHT,
  SOLID_CALENDER_CIRCLE_EXCLAMATION,
  SOLID_CALENDER_CLOCK,
  SOLID_CIRCLE,
  SOLID_CIRCLE_DASHED,
  SOLID_CIRCLE_PAUSE,
  SOLID_FLAG,
  SOLID_FLAG_PENNANT,
  SOLID_FLAG_SWALLOWTAIL,
  SOLID_HALF_CIRCLE_STROKE,
} from "../../constants/FontAwsomeIcons";
import {
  getUserAccountId,
  getUserProfileName,
  hasUserProRole,
} from "../../services/authServices";
import { shouldShowProUser } from "../../pages/kanban/utility/kanbanUtils";

export const formatDate = (value: any, format: string) => {
  const date = stringToDate(value);
  if (date.getFullYear() <= DEFAULT_YEAR) {
    return "N/A";
  }
  return moment(date).format(format);
};

export const isDefaultYear = (value: string) => {
  const date = stringToDate(value);
  return date.getFullYear() <= DEFAULT_YEAR;
};

export const isDefaultTime = (value: string) => {
  return value === DEFAULT_TIME;
};

export const handleScheduledDate = (
  scheduledDate: string,
  scheduledTime?: string
) => {
  const dateNow = stringToDate();
  let scheduledDateFlag;
  if (!scheduledTime?.trim()) {
    scheduledDateFlag = compareTwoDates(stringToDate(scheduledDate), dateNow);
  } else {
    scheduledDateFlag = compareTwoDatesTime(
      getDateTime(scheduledDate, scheduledTime).toDate(),
      dateNow
    );
  }

  if (scheduledDateFlag === -1) {
    return {
      badgeIcon: SOLID_CALENDER_CIRCLE_EXCLAMATION,
      badgeClass: "badge-danger-light-bg-w pl-0",
    };
  } else
    return {
      badgeIcon: `${SOLID_CALENDER_CLOCK} calendarIcon`,
      badgeClass: "badge-secondary ssspl-0",
    };
};

export const getBadgeName = (type: string | null | undefined): string => {
  switch (type) {
    case ResManPriorityMap.PRIORITY_MEDIUM:
      return `Medium`;
    case ResManPriorityMap.PRIORITY_HIGH:
      return `High`;
    case ResManPriorityMap.PRIORITY_DEFAULT:
    case ResManPriorityMap.PRIORITY_LOW:
      return ``;
    case WorkOrderConstants.ON_HOLD:
      return `On Hold`;
    case WorkOrderConstants.VIEWED:
      return "New";
    default:
      return type ?? "";
  }
};

export const isWorkOrderClosedStatus = (
  status: string | undefined
): boolean => {
  return status
    ? [
        WorkOrderConstants.DONE,
        WorkOrderConstants.CANCELLED,
        WorkOrderConstants.CLOSED,
      ].indexOf(status) > -1
    : false;
};

export const getBadgeColor = (
  priorityType: string | null | undefined
): string => {
  switch (priorityType) {
    case ResManPriorityMap.PRIORITY_MEDIUM:
      return `badge-danger-lighter`;
    case ResManPriorityMap.PRIORITY_HIGH:
      return `badge-danger-light`;
    case ResManPriorityMap.PRIORITY_DEFAULT:
    case ResManPriorityMap.PRIORITY_LOW:
      return ``;
    case WorkOrderConstants.ON_HOLD:
      return `badge-gray`;
    default:
      return `badge-primary`;
  }
};

export const getBadgeIconNdColor = (
  type: string | null | undefined
): string => {
  switch (type) {
    case WorkOrderConstants.ASSIGNMENT_BADGE:
      return `${SOLID_ARROW_RIGHT} badge-warn-lighter-bg-w font-size-xsm  pl-2`;
    case ResManPriorityMap.PRIORITY_MEDIUM:
      return `${SOLID_FLAG} badge-danger-lighter-bg-w font-size-13  pl-2`;
    case ResManPriorityMap.PRIORITY_HIGH:
      return `${SOLID_FLAG} badge-danger-light-bg-w font-size-13  pl-2`;
    case ResManPriorityMap.PRIORITY_DEFAULT:
    case ResManPriorityMap.PRIORITY_LOW:
      return `${SOLID_FLAG} badge-danger-lighter-bg-w font-size-13  pl-2 cl-gray`;
    case WorkOrderConstants.IN_PROGRESS:
      return `${SOLID_HALF_CIRCLE_STROKE} pl-2 font-size-13`;
    case WorkOrderConstants.NOT_VIEWED:
      return `${SOLID_CIRCLE_DASHED} pl-2 badge-primary-bg-w font-size-13 `;
    case WorkOrderConstants.NOT_STARTED:
      return `${SOLID_CIRCLE} pl-2 font-size-13`;
    case WorkOrderConstants.ON_HOLD:
      return `${REGULAR_CIRCLE_PAUSE} pl-2 badge-gray-bg-w font-size-13`;
    default:
      return `badge-primary`;
  }
};
export const getDateTime = (dateString = "", timeString = "") => {
  const date = moment();
  const dateArray = dateString.split("/");
  const month = dateArray[0];
  const day = dateArray[1];
  const year = dateArray[2];
  const timeArray = timeString.split(":");
  const hour = timeArray[0];
  const minute = timeArray[1];
  date
    .year(+year)
    .month(+month - 1)
    .date(+day)
    .hour(+hour)
    .minute(+minute);
  return date;
};

export const jobPriorityMap = (jobStatus: string | undefined) => {
  switch (jobStatus) {
    case WorkOrderConstants.PRIORITY_HIGH:
      return { label: "High", iconArray: [1, 2, 3] };
    case WorkOrderConstants.PRIORITY_MEDIUM:
      return { label: "Medium", iconArray: [1, 2] };
    case WorkOrderConstants.PRIORITY_LOW:
      return { label: "Low", iconArray: [1] };
    default:
      return { label: "Not Set", statusClass: "" };
  }
};

export const appointmentMap = (appointment: string | undefined) => {
  switch (appointment) {
    case RESMAN_APPOINTMENT_CONSTANT.RMA_CALL:
      return "Call";
    case RESMAN_APPOINTMENT_CONSTANT.RMA_NOT_REQUIRED:
      return "Not Required";
    case RESMAN_APPOINTMENT_CONSTANT.RMA_PERMISSION_GIVEN:
      return "Permission Given";
    case RESMAN_APPOINTMENT_CONSTANT.RMA_PERMISSION_NOT_GIVEN:
      return "Permission Not Given";
    default:
      return "Not Available";
  }
};

export const isScheduleTimeValid = (
  scheduledDate?: string,
  scheduledTime?: string
) => {
  // consoleLog(scheduledTime);
  return (
    !!scheduledDate?.trim() &&
    !isDefaultYear(scheduledDate) &&
    !!scheduledTime?.trim() &&
    !isDefaultTime(scheduledTime) &&
    scheduledTime !== "-" &&
    scheduledTime !== "00:00"
  );
};

export const isScheduleDateValid = (scheduledDate?: string) => {
  return (
    !!scheduledDate?.trim() &&
    scheduledDate !== "-" &&
    !isDefaultYear(scheduledDate)
  );
};

export const getProUserInfo = (
  proUsers: ProUserProp[],
  providerId: string
): ProUserProp | undefined => {
  return proUsers.find((proUser: ProUserProp) => proUser.id === providerId);
};

export const isJobAssignableToUser = (job: Job): boolean => {
  if (!job?.propertyId) return false;
  const proUser: ProUserProp = {
    name: getUserProfileName(),
    id: getUserAccountId(),
    propertyId: job.propertyId,
  };
  return hasUserProRole() && shouldShowProUser(proUser, job.propertyId);
};

export const isJobIsUnassigned = (job: Job): boolean => {
  return (
    job?.jobAssignment?.providerName === "" ||
    job?.jobAssignment?.providerName === MAINTENANCE_RESPROP
  );
};

export const mapJobStatuses = (
  status: string[],
  isFromAssignedColumn = false
): string[] => {
  const job_status: string[] = [];
  status.forEach((s) => {
    if (s === WorkOrderConstants.ASSIGNED) {
      job_status.push(WorkOrderConstants.ASSIGNED);
      !isFromAssignedColumn && job_status.push(WorkOrderConstants.UNASSIGNED);
    } else {
      job_status.push(s);
    }
  });
  return Array.from(new Set(job_status));
};

const onCategorySelected = (
  category: Category,
  selectedCategories: string[]
): string[] => {
  if (!!category?.categories) {
    const isChecked = category?.categories?.every((i: any) =>
      selectedCategories.includes(i?.id)
    );

    if (!isChecked) {
      const menuItemsId: string[] = category?.categories
        .filter((item: any) => findInArray(selectedCategories, item.id) === -1)
        .map((i: any) => i.id);
      return [...selectedCategories, ...menuItemsId];
    } else {
      const newMenuItemsId: string[] = [...selectedCategories];
      category?.categories?.forEach((item: any) => {
        const index = findInArray(newMenuItemsId, item.id);
        if (index > -1) {
          newMenuItemsId.splice(index, 1);
        }
      });
      return [...newMenuItemsId];
    }
  } else {
    const idxInSelectedProperties = findInArray(
      selectedCategories,
      category.id
    );
    let newCategories: string[] = Array.from(selectedCategories);
    if (idxInSelectedProperties > -1) {
      newCategories.splice(idxInSelectedProperties, 1);
    } else {
      newCategories.push(category.id);
    }
    return newCategories;
  }
};

const onPropertySelected = (
  property: FilterStatus,
  selectedProperty: string[]
): string[] => {
  const idxInSelectedProperties = findInArray(selectedProperty, property.id);
  let newSelectedProperties: string[] = Array.from(selectedProperty);
  if (idxInSelectedProperties > -1) {
    newSelectedProperties.splice(idxInSelectedProperties, 1);
  } else {
    newSelectedProperties.push(property.id);
  }
  return newSelectedProperties;
};

const onStatusSelected = (
  statusCategory: FilterStatus,
  selectedStatus: string[]
): string[] => {
  const idxInSelectedStatus = findInArray(selectedStatus, statusCategory.id);
  let newSelectedStatusCategories: string[] = Array.from(selectedStatus);
  if (idxInSelectedStatus > -1) {
    newSelectedStatusCategories.splice(idxInSelectedStatus, 1);
  } else {
    newSelectedStatusCategories.push(statusCategory.id);
  }
  return newSelectedStatusCategories;
};

const onDateCategorySelected = (dateCategory: DateCategory): string[] => {
  return [dateCategory.id];
};

export const filterUtils = {
  onDateCategorySelected,
  onStatusSelected,
  onPropertySelected,
  onCategorySelected,
};

export const compareWithPreviousJobData = ({
  updatedState,
  previousState,
  categories = [],
}: {
  updatedState: Job;
  previousState: Job;
  categories?: Category[];
}): { reqBody: JobItemReqBody; updatedJobDetails: Job } => {
  let modifiedKey: { [key: string]: any } = {};
  (Object.keys(updatedState) as (keyof typeof updatedState)[]).forEach(
    (key) => {
      if (updatedState[key] !== previousState[key] && key !== "jobAssignment") {
        modifiedKey[key] = updatedState[key];
      }
    }
  );
  const updatedJobAssignment = updatedState.jobAssignment;
  const prevJobAssginment = previousState.jobAssignment;

  if (updatedJobAssignment?.providerId !== prevJobAssginment?.providerId) {
    if (!updatedJobAssignment?.providerId.trim()) {
      modifiedKey.jobStatus = JobStatusMap.UNASSIGNED;
    } else {
      modifiedKey.jobStatus = JobStatusMap.ASSIGNED;
      modifiedKey.providerId = updatedJobAssignment.providerId;
    }
  }
  const reqBody = { ...modifiedKey };
  if ("scheduledTime" in reqBody || "scheduledDate" in reqBody) {
    reqBody.scheduledDateTime = {
      scheduledDate: !modifiedKey?.scheduledDate?.trim()
        ? "scheduledDate" in reqBody
          ? ""
          : previousState?.scheduledDate
        : modifiedKey?.scheduledDate,
      scheduledTime: !!modifiedKey?.scheduledTime?.trim()
        ? modifiedKey?.scheduledTime !== "-" &&
          modifiedKey?.scheduledTime !== "00:00"
          ? modifiedKey.scheduledTime
          : "00:00"
        : previousState?.scheduledTime
        ? previousState?.scheduledTime
        : "",
    };
    "scheduledTime" in reqBody && delete reqBody.scheduledTime;
    "scheduledDate" in reqBody && delete reqBody.scheduledDate;
  }

  if ("categoryName" in reqBody) {
    const categoryIdIndex = findInArray(
      categories,
      reqBody.categoryName,
      "name"
    );
    if (categoryIdIndex > -1) {
      reqBody.categoryId = categories[categoryIdIndex].id;
    } else {
      delete modifiedKey.categoryName;
    }
    delete reqBody.categoryName;
  }
  return {
    reqBody: reqBody,
    updatedJobDetails: {
      ...updatedState,
      ...modifiedKey,
    },
  };
};
