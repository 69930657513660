import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import {
  ADD_ITEM,
  ClosedWoFilters,
  DateRanges,
  OpenWoFilters,
  REMOVE_JOB_ITEM,
  StatusFiltersCategories,
} from "../../constants/GlobalConstants";
import { categories } from "../../@fakedb/workorderDummyData";
import {
  getSortedValue,
  JobStatusMap,
  UNASSIGNED_BOARD_ID,
  WorkOrderConstants,
} from "../../constants/WorkOrderConstants";
import consoleLog from "../../helpers/consoleLog";
import { deepClone, findInArray } from "../../helpers/utility";
import {
  sortBoardData,
  sortHiddenPropertyJobsByName,
} from "../../helpers/utils";
import {
  BoardProp,
  Category,
  DateCategory,
  FilterStatus,
  HiddenJobs,
  ICreateWoModalMetaData,
  IMovedCardItemMetaData,
  IPropertyId,
  Job,
  Property,
  ProUserProp,
} from "../../models/kanabn";
import {
  ICurrentPropertyPayloadAction,
  IJobItemProp,
  IMoveBoardPayload,
  IReorderPayloadAction,
  IUpdatedKanbanItemProp,
  ISelectedPropertyPayloadAction,
  IOnStartDrag,
  IRecentlyMovedCardItemsPayloadAction,
  ISelectedCategoryPayloadAction,
  ISelectedDateCategoryPayloadAction,
  ISetDefaultStatusFilterPayloadAction,
  ISelectedStatusCategoryPayloadAction,
} from "../../models/PayloadModels";
import { hasFullAccess } from "../../services/authServices";
import {
  fetchKanbanBoardData,
  getCategoriesThunks,
  getPropertiesThunks,
} from "../actions/kanban/kanBanThunks";
import { KANBAN_BOARD_REDUCER } from "../reducerConstants";
import { getAllWorkOrdersCategory } from "../../helpers/transformers/woCategoryTransformets";
import amplitudeService from "../../services/amplitudeService";
import commonServices from "../../services/commonServises";
import { formatDate, getDateTime } from "../../helpers/utility/workOrdersUtils";
import { RouterConstants } from "../../constants/RouterConstants";

export interface IKanbanBoardState {
  kanbanBoardData: BoardProp[];
  selectedProperty: number;
  filteredBoard: BoardProp[];
  properties: Property[];
  currentProperty: Property | null;
  selectedProperties: string[];
  isPropertyFetching: boolean;
  isBoardDataFetching: boolean;
  proUsers: ProUserProp[];
  createWoModalMetaData: ICreateWoModalMetaData;
  currentDroppableProperty?: string | null;
  recentlyMovedCardItems: Array<IMovedCardItemMetaData>;
  selectedCategories: string[];
  categories: Category[];
  selectedDateCategory: string[];
  dateCategory: DateCategory[];
  isFilterUsed: boolean;
  statusFilterCategories: FilterStatus[];
  selectedStatusFilters: string[];
  defaultFilterState: {
    statusFilter: string[];
    categoryFilter: string[];
    propertyFilter: string[];
    dateFilter: string[];
  };
}

const initialState: IKanbanBoardState = {
  kanbanBoardData: [],
  selectedProperty: 101,
  filteredBoard: [],
  properties: [],
  currentProperty: null,
  isPropertyFetching: false,
  selectedProperties: [],
  isBoardDataFetching: false,
  proUsers: [],
  createWoModalMetaData: {
    isVisible: false,
  },
  currentDroppableProperty: null,
  recentlyMovedCardItems: [],
  selectedCategories: [],
  categories: [],
  dateCategory: DateRanges,
  selectedDateCategory: [],
  isFilterUsed: false,
  statusFilterCategories: [],
  selectedStatusFilters: [],
  defaultFilterState: {
    statusFilter: [],
    categoryFilter: [],
    propertyFilter: [],
    dateFilter: [],
  },
};

const updateBoardItemAssignee = (
  destinationBoardId: string,
  destinationBoardName: string,
  jobItem: Job
): Job => {
  let newJobInfo: Job = {
    ...jobItem,
    jobAssignment: {
      assignedAt: new Date().toString(),
      providerId:
        destinationBoardId === UNASSIGNED_BOARD_ID ? "" : destinationBoardId,
      providerName:
        destinationBoardId === UNASSIGNED_BOARD_ID ? "" : destinationBoardName,
    },
    jobStatus:
      destinationBoardId === UNASSIGNED_BOARD_ID
        ? JobStatusMap.UNASSIGNED
        : JobStatusMap.ASSIGNED,
    isMoved: true,
  };
  return newJobInfo;
};

export const findJobInBoard = (
  boardData: BoardProp[],
  job: Job | string,
  key: "id" | "id"
): { boardIndex: number; jobIndex: number } => {
  let boardIndex = -1,
    jobIndex = -1;
  for (let i = 0; i < boardData.length; i++) {
    const jobs: Job[] = boardData[i].jobs;
    for (let j = 0; j < jobs.length; j++) {
      if (typeof job === "string" && job === jobs[j][key]) {
        boardIndex = i;
        jobIndex = j;
        break;
      } else if (typeof job !== "string" && job[key] === jobs[j][key]) {
        boardIndex = i;
        jobIndex = j;
        break;
      }
    }
  }

  return { boardIndex, jobIndex };
};

const kanbanSlice = createSlice({
  name: KANBAN_BOARD_REDUCER,
  initialState,
  reducers: {
    storeKanbanData: (state, action) => {},
    changeProperty: (state, action) => {
      state.selectedProperty = action.payload;
      state.filteredBoard = [];
    },
    onStartDrag: (state, action: PayloadAction<IOnStartDrag>) => {
      const { index, droppableId, isDragEnd } = action.payload;
      if (isDragEnd) {
        state.currentDroppableProperty = null;
        return;
      }
      const boardData = current(state).kanbanBoardData;
      const sourceBoardIndex: number = findInArray(
        boardData,
        droppableId,
        "boardId"
      );
      const job: Job = boardData[sourceBoardIndex].jobs[index];
      state.currentDroppableProperty = job.propertyId;
    },

    moveBoardItem: (state, action: PayloadAction<IMoveBoardPayload>) => {
      const boardData = current(state).kanbanBoardData;
      const recentlyMovedCardItems: IMovedCardItemMetaData[] = deepClone(
        current(state).recentlyMovedCardItems
      );
      state.filteredBoard = [];
      const {
        source,
        destination,
        jobItem,
        showLoader = false,
      } = action.payload;
      const sourceBoardIndex: number = findInArray(
        boardData,
        source.droppableId,
        "boardId"
      );
      const destBoardIndex: number = findInArray(
        boardData,
        destination.droppableId,
        "boardId"
      );
      if (sourceBoardIndex === -1 || destBoardIndex === -1) return;

      const sourceClone: Job[] = deepClone(boardData[sourceBoardIndex].jobs);
      let destClone: Job[] = deepClone(boardData[destBoardIndex].jobs);
      let sourceJobIndex: number;
      let destJobIndex: number = 0;
      if (!!jobItem) {
        sourceJobIndex = findInArray(sourceClone, jobItem.id, "id");
      } else {
        sourceJobIndex = source?.index ?? 0;
        destJobIndex = destination?.index ?? 0;
      }
      if (!!showLoader) {
        const recentCardItem: IMovedCardItemMetaData = {
          id: boardData[sourceBoardIndex].jobs[sourceJobIndex].id,
          previousDestination: boardData[destBoardIndex].boardId,
          previousSource: boardData[sourceBoardIndex].boardId,
        };
        state.recentlyMovedCardItems = [
          ...recentlyMovedCardItems,
          recentCardItem,
        ];
      }

      const [removed] = sourceClone.splice(sourceJobIndex, 1);

      destClone.splice(destJobIndex, 0, removed);

      let destinationData = [...destClone];
      destinationData[destJobIndex] = {
        ...updateBoardItemAssignee(
          boardData[destBoardIndex].boardId,
          boardData[destBoardIndex].boardName,
          !!jobItem ? jobItem : destinationData[destJobIndex]
        ),
      };

      destClone = sortBoardData([...destinationData]);
      state.kanbanBoardData[sourceBoardIndex].jobs = sourceClone;
      state.kanbanBoardData[destBoardIndex].jobs = destClone;
      state.currentDroppableProperty = null;
    },

    reorderBoard: (state, action: PayloadAction<IReorderPayloadAction>) => {
      let boardData: BoardProp[] = current(state).kanbanBoardData;
      state.filteredBoard = [];
      const { startIndex, destinationIndex, droppableId } = action.payload;
      const boardIndex: number = findInArray(boardData, droppableId, "boardId");
      const result: Job[] = deepClone(boardData[boardIndex].jobs);
      result[startIndex] = {
        ...result[startIndex],
        isMoved: true,
      };
      const [removed] = result.splice(startIndex, 1);
      result.splice(destinationIndex, 0, removed);
      state.kanbanBoardData[boardIndex].jobs = sortBoardData(result);
      state.currentDroppableProperty = null;
    },

    changeCurrentProperty: (
      state,
      action: PayloadAction<ICurrentPropertyPayloadAction>
    ) => {
      const { currentProperty } = action.payload;
      state.currentProperty = currentProperty;
      state.selectedProperties = [currentProperty.id];
    },

    searchOnKanBanBoard: (state, action) => {
      const boardData = current(state).kanbanBoardData;
      const searchQuery: string = action.payload;

      if (!searchQuery.trim()) {
        state.filteredBoard = [];
        return;
      }
      const filteredData: BoardProp[] = boardData.map((board: BoardProp) => {
        const jobs: Job[] = board.jobs.filter((job: Job) => {
          const query = searchQuery.toLowerCase();
          const startsWith =
            job.unitName.toLowerCase().startsWith(query) ||
            job.description.toLowerCase().startsWith(query) ||
            job?.propertyName?.toLowerCase()?.startsWith(query) ||
            formatDate(job?.createdTime, "M/D")?.startsWith(query) ||
            getDateTime(job?.scheduledDate, job?.scheduledTime)
              ?.format(" h:mma")
              ?.startsWith(query);
          const includes =
            job.unitName.toLowerCase().includes(query) ||
            job.description.toLowerCase().includes(query) ||
            job?.propertyName?.toLowerCase().includes(query) ||
            formatDate(job?.createdTime, "M/D")?.includes(query) ||
            getDateTime(job?.scheduledDate, job?.scheduledTime)
              ?.format(" h:mma")
              ?.includes(query);

          if (startsWith) {
            return startsWith;
          } else if (!startsWith && includes) {
            return includes;
          } else return null;
        });
        return {
          ...board,
          jobs,
        };
      });
      state.filteredBoard = filteredData;
    },

    updateKanbanBoardItemJobLists: (
      state,
      action: PayloadAction<{
        jobs: Job[];
        boardId: string;
        hasJobOnBoard?: boolean;
      }>
    ) => {
      const boardData = current(state).kanbanBoardData;
      const selectedProperties: string[] = current(state).selectedProperties;
      const { jobs, boardId } = action.payload;
      const boardIndex = findInArray(boardData, boardId, "boardId");

      const hiddenJobs: HiddenJobs[] = [];
      const showAbleJobs: Job[] = jobs.filter(
        (job: Job) =>
          !!job?.propertyId && selectedProperties.includes(job.propertyId)
      );

      for (let i = 0; i < jobs.length; i++) {
        const job: Job = jobs[i];
        if (!!job?.propertyId && !selectedProperties.includes(job.propertyId)) {
          const index = findInArray(hiddenJobs, job.propertyId, "propertyId");
          if (index === -1) {
            hiddenJobs.push({
              propertyId: job.propertyId,
              propertyName: job.propertyName || "",
              total: 1,
            });
          } else {
            hiddenJobs[index] = {
              ...hiddenJobs[index],
              total: hiddenJobs[index].total + 1,
            };
          }
        }
      }
      if (boardIndex > -1) {
        state.kanbanBoardData[boardIndex].jobs = sortBoardData(showAbleJobs);
        state.kanbanBoardData[boardIndex].hiddenJobs = hiddenJobs.sort(
          sortHiddenPropertyJobsByName
        );
      }
    },

    updateKanbanBoardItemProperties: (
      state,
      action: PayloadAction<IUpdatedKanbanItemProp>
    ) => {
      const { job, type } = action.payload;
      const boardData = current(state).kanbanBoardData;
      const { boardIndex, jobIndex } = findJobInBoard(boardData, job, "id");
      const boardJobs: Job[] = deepClone(
        state.kanbanBoardData[boardIndex].jobs
      );
      if (type === REMOVE_JOB_ITEM) {
        boardJobs.splice(jobIndex, 1);
      } else {
        boardJobs[jobIndex] = {
          ...job,
          isMoved: true,
        };
      }

      state.kanbanBoardData[boardIndex].jobs = sortBoardData(boardJobs);
    },

    showHighlightInJobCardMode: (
      state,
      action: PayloadAction<IJobItemProp>
    ) => {
      const { job } = action.payload;
      const boardData = current(state).kanbanBoardData;
      const { boardIndex, jobIndex } = findJobInBoard(boardData, job, "id");
      const jobItemInState = boardData[boardIndex].jobs[jobIndex];
      state.kanbanBoardData[boardIndex].jobs[jobIndex] = {
        ...jobItemInState,
        isMoved: true,
      };
    },

    hideHighlightInJobCardMode: (
      state,
      action: PayloadAction<IJobItemProp>
    ) => {
      const { job } = action.payload;

      const boardData = current(state).kanbanBoardData;
      const { boardIndex, jobIndex } = findJobInBoard(boardData, job, "id");
      const jobItemInState = boardData[boardIndex].jobs[jobIndex];
      state.kanbanBoardData[boardIndex].jobs[jobIndex] = {
        ...jobItemInState,
        isMoved: false,
      };
    },

    updateCreateWoMetaData: (
      state,
      action: PayloadAction<ICreateWoModalMetaData>
    ) => {
      const { isVisible } = action.payload;
      state.createWoModalMetaData = {
        isVisible,
      };
    },

    updateSelectedProperties: (
      state,
      action: PayloadAction<ISelectedPropertyPayloadAction>
    ) => {
      const properties = current(state).properties;
      const { selectedProperties } = action.payload;

      commonServices.saveLastRefreshedTime();
      amplitudeService.propertyFilterAppliedEventFor({
        properties_selected: selectedProperties,
        properties: properties,
      });
      state.selectedProperties = selectedProperties;
      state.isFilterUsed = true;
    },

    updateSelectedCategories: (
      state,
      action: PayloadAction<ISelectedCategoryPayloadAction>
    ) => {
      const categories = current(state).categories;
      const { selectedCategories } = action.payload;

      commonServices.saveLastRefreshedTime();
      amplitudeService.categoryFilterUsedEventFor({
        category_selected: selectedCategories,
        categories: categories,
      });
      state.selectedCategories = selectedCategories;
      state.isFilterUsed = true;
    },

    updateSelectedStatusCategories: (
      state,
      action: PayloadAction<ISelectedStatusCategoryPayloadAction>
    ) => {
      const statusFilterCategories = current(state).statusFilterCategories;
      const { selectedStatusFilters } = action.payload;

      commonServices.saveLastRefreshedTime();
      amplitudeService.statusFilterUsedEventFor({
        status_categories: statusFilterCategories,
        status_category_selected: selectedStatusFilters,
      });
      state.selectedStatusFilters = selectedStatusFilters;
      state.isFilterUsed = true;
    },

    updateSelectedDateCategory: (
      state,
      action: PayloadAction<ISelectedDateCategoryPayloadAction>
    ) => {
      const dateCategories = current(state).dateCategory;
      const { selectedDateCategories } = action.payload;

      commonServices.saveLastRefreshedTime();
      amplitudeService.dateFilterUsedEventFor({
        date_categories: dateCategories,
        date_categories_selected: selectedDateCategories,
      });
      state.selectedDateCategory = selectedDateCategories;
      state.isFilterUsed = true;
    },

    selectDefaultDate: (state) => {
      const previousSelectedDateCategory = current(state).selectedDateCategory;
      state.selectedDateCategory =
        previousSelectedDateCategory?.length > 0
          ? previousSelectedDateCategory
          : [DateRanges[0].id];
      state.defaultFilterState.dateFilter = [DateRanges[0].id];
    },

    setDefaultStatusFilters: (
      state,
      action: PayloadAction<ISetDefaultStatusFilterPayloadAction>
    ) => {
      const previousSelectedStatusFilters =
        current(state).selectedStatusFilters;
      const { location } = action.payload;
      let filters: any[] = [];
      switch (location) {
        case RouterConstants.KANBAN:
          filters = OpenWoFilters;
          break;
        case RouterConstants.COMPLETED_WORK_ORDERS:
          filters = ClosedWoFilters;
          break;
        default:
          filters = [...OpenWoFilters, ...ClosedWoFilters];
          break;
      }
      let newStatusFilters = previousSelectedStatusFilters.filter(
        (p: string) => findInArray(filters, p, "id") > -1
      );

      state.defaultFilterState.statusFilter = filters.map((i) => i.id);
      state.statusFilterCategories = filters;
      state.selectedStatusFilters =
        newStatusFilters.length > 0
          ? newStatusFilters
          : filters.map((i) => i.id);
    },

    onResetSelectedCategories: (state) => {
      const defaultFilterState = current(state).defaultFilterState;
      state.selectedCategories = defaultFilterState.categoryFilter;
    },

    onResetSelectedDateCategories: (state) => {
      const defaultFilterState = current(state).defaultFilterState;
      state.selectedDateCategory = defaultFilterState.dateFilter;
    },

    onResetStatusCategories: (state) => {
      const defaultFilterState = current(state).defaultFilterState;
      state.selectedStatusFilters = defaultFilterState.statusFilter;
    },

    onResetSelectedProperties: (state) => {
      const defaultFilterState = current(state).defaultFilterState;
      state.selectedProperties = defaultFilterState.propertyFilter;
    },

    onResetFilter: (state) => {
      const defaultFilterState = current(state).defaultFilterState;

      commonServices.saveLastRefreshedTime();
      amplitudeService.filterResetClickedEventFor();
      state.selectedCategories = defaultFilterState.categoryFilter;
      state.selectedDateCategory = defaultFilterState.dateFilter;
      state.selectedProperties = defaultFilterState.propertyFilter;
      state.selectedStatusFilters = defaultFilterState.statusFilter;
      state.isFilterUsed = false;
    },

    removeRecentlyMovedCardItem: (
      state,
      action: PayloadAction<IRecentlyMovedCardItemsPayloadAction>
    ) => {
      const boardData = current(state).kanbanBoardData;
      const recentlyMoveCardItems: IMovedCardItemMetaData[] = deepClone(
        current(state).recentlyMovedCardItems
      );

      const { id, type = ADD_ITEM, isSuccess } = action.payload;
      const index = findInArray(recentlyMoveCardItems, id, "id");
      if (index === -1) return;
      if (!isSuccess) {
        const recentCardItem: IMovedCardItemMetaData =
          recentlyMoveCardItems[index];
        const { jobIndex, boardIndex } = findJobInBoard(boardData, id, "id");

        const payload: IMoveBoardPayload = {
          source: { droppableId: recentCardItem.previousDestination },
          destination: { droppableId: recentCardItem.previousSource },
          jobItem: {
            ...boardData[boardIndex].jobs[jobIndex],
          },
        };

        kanbanSlice.caseReducers.moveBoardItem(state, { payload, type: "" });
      } else {
        const { jobIndex, boardIndex } = findJobInBoard(boardData, id, "id");
        const job = boardData[boardIndex].jobs[jobIndex];
        const payload: IJobItemProp = {
          job: job,
        };
        // kanbanSlice.caseReducers.showHighlightInJobCardMode(state, {
        //   payload,
        //   type: "",
        // });
      }

      recentlyMoveCardItems.splice(index, 1);
      state.recentlyMovedCardItems = [...recentlyMoveCardItems];
    },

    onLogOut: (state) => {
      state.kanbanBoardData = [];
      state.filteredBoard = [];
      state.selectedProperty = 0;
      state.currentDroppableProperty = null;
      state.currentProperty = null;
      state.properties = [];
      state.isPropertyFetching = false;
      state.selectedProperties = [];
      state.isBoardDataFetching = false;
      state.proUsers = [];
      state.createWoModalMetaData = {
        isVisible: false,
      };
      state.currentDroppableProperty = null;
      state.recentlyMovedCardItems = [];
      state.selectedCategories = [];
      state.categories = [];
      state.selectedDateCategory = [];
      state.statusFilterCategories = [];
      state.selectedStatusFilters = [];
      state.defaultFilterState = {
        statusFilter: [],
        categoryFilter: [],
        propertyFilter: [],
        dateFilter: [],
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchKanbanBoardData.fulfilled, (state, { payload }) => {
      state.kanbanBoardData = payload?.workOrders ?? [];
      state.proUsers = payload?.proUsers ?? [];
      state.isBoardDataFetching = false;
    });
    builder.addCase(fetchKanbanBoardData.pending, (state, { payload }) => {
      state.kanbanBoardData = [];
      state.isBoardDataFetching = true;
    });
    builder.addCase(fetchKanbanBoardData.rejected, (state, { payload }) => {
      // state.kanbanBoardData = payload;
      state.isBoardDataFetching = false;
    });
    builder.addCase(getCategoriesThunks.pending, (state, { payload }) => {
      // state.isPropertyFetching = true;
    });
    builder.addCase(getCategoriesThunks.rejected, (state, { payload }) => {
      state.categories = categories;
      const selectedCategoryFilter = getAllWorkOrdersCategory(categories);
      state.defaultFilterState.categoryFilter = selectedCategoryFilter.map(
        (i) => i.id
      );
    });
    builder.addCase(getCategoriesThunks.fulfilled, (state, { payload }) => {
      const previousCategories = deepClone(current(state).selectedCategories);

      let newSelectedCategories = previousCategories.filter(
        (p: string) =>
          findInArray(payload?.categories ?? categories, p, "id") > -1
      ); // checking if previous selected item are available in selected list

      const defaultCategories: Category[] = getAllWorkOrdersCategory(
        payload?.categories || categories
      );

      let selectedCategories: string[] =
        newSelectedCategories.length > 0
          ? [...newSelectedCategories]
          : defaultCategories.map((i) => i.id);

      state.defaultFilterState.categoryFilter = defaultCategories.map(
        (i) => i.id
      );

      state.selectedCategories = selectedCategories;
      state.categories = payload?.categories ?? categories;
    });

    builder.addCase(getPropertiesThunks.pending, (state, { payload }) => {
      state.isPropertyFetching = true;
    });
    builder.addCase(getPropertiesThunks.rejected, (state, { payload }) => {
      state.properties = [];
      state.isPropertyFetching = false;
    });
    builder.addCase(getPropertiesThunks.fulfilled, (state, { payload }) => {
      const previousSelectedProperties: string[] =
        current(state).selectedProperties;

      const firstProperty = payload?.properties?.[0];
      state.isPropertyFetching = false;
      state.properties = payload?.properties ?? [];

      let selectedProperties: string[] = [];
      let defaultProperties: string[] = [];
      if (hasFullAccess()) {
        let properties = previousSelectedProperties.filter(
          (p: string) => findInArray(payload?.properties ?? [], p, "id") > -1
        ); // checking if previous selected properties are available in property list

        selectedProperties =
          properties.length > 0
            ? [...properties]
            : firstProperty
            ? [firstProperty.id]
            : []; //only selected the first one on first load

        defaultProperties = firstProperty ? [firstProperty.id] : [];
      } else {
        selectedProperties = payload?.properties?.map((p) => p.id) ?? [];
        defaultProperties = payload?.properties?.map((p) => p.id) ?? [];
      }

      state.defaultFilterState.propertyFilter = defaultProperties;
      state.selectedProperties = selectedProperties;
      state.currentProperty = payload?.properties?.[0] ?? null;
    });
  },
});

export const {
  storeKanbanData,
  changeProperty,
  searchOnKanBanBoard,
  moveBoardItem,
  reorderBoard,
  changeCurrentProperty,
  updateKanbanBoardItemProperties,
  hideHighlightInJobCardMode,
  updateCreateWoMetaData,
  updateSelectedProperties,
  onResetSelectedProperties,
  onStartDrag,
  onLogOut,
  updateKanbanBoardItemJobLists,
  removeRecentlyMovedCardItem,
  updateSelectedCategories,
  selectDefaultDate,
  updateSelectedDateCategory,
  onResetFilter,
  setDefaultStatusFilters,
  updateSelectedStatusCategories,
  onResetSelectedCategories,
  onResetStatusCategories,
  onResetSelectedDateCategories,
  showHighlightInJobCardMode,
} = kanbanSlice.actions;

export default kanbanSlice.reducer;
