import { Category } from "../../models/kanabn";
import consoleLog from "../consoleLog";
import { deepClone, findInArray } from "../utility";

export const woCategoryTransform = (categories: Category[]): Category[] => {
  const makeReadyIndex = findInArray(categories, "Make Ready", "name");
  let newCategoryList: Category[] = deepClone(categories);
  if (makeReadyIndex > -1) {
    newCategoryList.splice(makeReadyIndex, 1);
  }
  return [
    {
      ...categories[makeReadyIndex],
    },
    {
      name: "Work Orders",
      id: "11111",
      categories: newCategoryList,
    },
  ];
};

export const getAllWorkOrdersCategory = (
  categories: Category[],
): Category[] => {
  return categories.filter((item: Category) => item.name !== "Make Ready");
};
