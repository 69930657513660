import { createAsyncThunk } from "@reduxjs/toolkit";
import consoleLog from "../../../helpers/consoleLog";
import amplitudeService from "../../../services/amplitudeService";
import authServices, { getUserRoles } from "../../../services/authServices";
import { LOGIN_DETAILS_REDUCER } from "../../reducerConstants";
import { onLogOut, selectDefaultDate } from "../../reducers/kanbanSlice";
import {
  getCategoriesThunks,
  getPropertiesThunks,
} from "../kanban/kanBanThunks";
// export const getAccessTokenThunk = createAsyncThunk(
//   `${LOGIN_DETAILS_REDUCER}/getAccessToken`,
//   async (payload, { rejectWithValue, dispatch }) => {
//     try {
//       if (window.location.hash) {
//         console.log(window.location.hash);
//         const callBackResponse = await handleLoginCallback();
//         const authData = setSession(callBackResponse, null);
//         console.log(authData, "callBackResponse", callBackResponse);
//         dispatch(setAuthData({ authData }));
//       }
//       const accessTokenInfo = await getAccessToken();

//       const userInfo = await getUserInfo(accessTokenInfo);
//       // consoleLog(accessTokenInfo?.idToken);
//       const roles = await getUserRoles(accessTokenInfo?.idToken);

//       const authData = setSession(accessTokenInfo, userInfo);
//       consoleLog("authData", authData);

//       // dispatch(getPropertiesThunks({ accessToken: accessTokenInfo?.idToken }));
//       return { authData: authData };
//     } catch (error) {
//       consoleLog(error);
//       rejectWithValue(error);
//     }
//   },
// );

export const logOutThunk = createAsyncThunk(
  `${LOGIN_DETAILS_REDUCER}/logOutThunk`,
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(onLogOut());
    amplitudeService.logOutClickedEventFor();
    return await authServices.logOut();
  },
);

export const loginThunk = createAsyncThunk(
  `${LOGIN_DETAILS_REDUCER}/logInThunk`,
  async (payload, { rejectWithValue, dispatch }) => {
    getUserRoles();
    dispatch(selectDefaultDate());
    dispatch(getCategoriesThunks());
    dispatch(getPropertiesThunks());
  },
);
