import { lazy, Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./assets/styles/theme.scss";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/lib/integration/react";
import SuspenseLoader from "./components/ui/spinner/SuspenseLoader";
import store from "./redux/store";
const LazyApp = lazy(() => import("./routes/RouteConfig"));

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistStore(store)}>
          <Suspense fallback={<SuspenseLoader />}>
            <BrowserRouter>
              <LazyApp />
              <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                draggable
                style={{
                  zIndex: 30000000000,
                }}
              />
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
