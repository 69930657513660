import { getSortedValue } from "../constants/WorkOrderConstants";
import { BoardProp, HiddenJobs, Job, Property } from "../models/kanabn";
import { stringToDate } from "./utility";
import { ProUserProp } from "../models/kanabn";
import { formatDate, getDateTime } from "./utility/workOrdersUtils";

export const sortBoardData = (boardJobs: Job[]): Job[] => {
  return [...boardJobs].sort((a, b) =>
    getSortedValue(
      a.priority,
      a.jobStatus,
      a.isOnHold,
      a.scheduledDate,
      a.createdTime,
    ) <=
    getSortedValue(
      b.priority,
      b.jobStatus,
      b.isOnHold,
      b.scheduledDate,
      b.createdTime,
    )
      ? -1
      : 1,
  );
};

export const sortCompletionBoardData = (boardJobs: Job[]): Job[] => {
  return [...boardJobs].sort((a, b) =>
    stringToDate(a.completedTime).getTime() >=
    stringToDate(b.completedTime).getTime()
      ? -1
      : 1,
  );
};

export const sortProUserByName = (a: ProUserProp, b: ProUserProp) => {
  let fa = a.name.toLowerCase(),
    fb = b.name.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export const sortPropertiesByName = (a: Property, b: Property) => {
  let fa = a.name.toLowerCase(),
    fb = b.name.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export const sortHiddenPropertyJobsByName = (a: HiddenJobs, b: HiddenJobs) => {
  let fa = a.propertyName.toLowerCase(),
    fb = b.propertyName.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export const filterBoard = (
  boardData: BoardProp[],
  searchQuery: string,
): BoardProp[] => {
  return boardData.map((board: BoardProp) => {
    const jobs: Job[] = board.jobs.filter((job: Job) => {
      const query = searchQuery.toLowerCase();
      const startsWith =
        job?.unitName?.toLowerCase().startsWith(query) ||
        job.description.toLowerCase().startsWith(query) ||
        job?.notes?.toLowerCase()?.startsWith(query) ||
        job?.propertyName?.toLowerCase()?.startsWith(query) ||
        formatDate(job?.createdTime, "M/D")?.startsWith(query) ||
        getDateTime(job?.scheduledDate, job?.scheduledTime)
          ?.format(" h:mma")
          ?.startsWith(query);
      const includes =
        job?.unitName?.toLowerCase().includes(query) ||
        job.description.toLowerCase().includes(query) ||
        job?.notes?.toLowerCase()?.includes(query) ||
        job?.propertyName?.toLowerCase().includes(query) ||
        formatDate(job?.createdTime, "M/D")?.includes(query) ||
        getDateTime(job?.scheduledDate, job?.scheduledTime)
          ?.format(" h:mma")
          ?.includes(query);

      if (startsWith) {
        return startsWith;
      } else if (!startsWith && includes) {
        return includes;
      } else return null;
    });
    return {
      ...board,
      jobs,
    };
  });
};
