import { compareTwoDates, stringToDate } from '../helpers/utility';

export const WorkOrderConstants = {
	TRUE: 'true',
	FALSE: 'false',

	VIEWED: 'VIEWED',
	NOT_VIEWED: 'NOT_VIEWED',
	ON_HOLD: 'ON_HOLD',
	NOT_STARTED: 'NOT_STARTED',
	IN__PROGRESS: 'In Progress',
	ASSIGNMENT_BADGE: 'ASSIGNMENT_BADGE',

	PRIORITY_MEDIUM: 'PRIORITY_MEDIUM',
	PRIORITY_HIGH: 'PRIORITY_HIGH',
	PRIORITY_DEFAULT: 'PRIORITY_DEFAULT',
	PRIORITY_LOW: 'PRIORITY_LOW',

	DONE: 'COMPLETED',
	IN_PROGRESS: 'IN_PROGRESS',
	UNASSIGNED: 'UNASSIGNED',
	CANCELLED: 'CANCELLED',
	CLOSED: 'CLOSED',
	ASSIGNED: 'ASSIGNED',
	CHANGE_REQUESTED: 'CHANGE_REQUESTED',
	SUPERSEDED: 'SUPERSEDED',
	SHADOW: 'SHADOW',
};

export const RESMAN_APPOINTMENT_CONSTANT = {
	RMA_CALL: 'RMA_CALL',
	RMA_NOT_REQUIRED: 'RMA_NOT_REQUIRED',
	RMA_PERMISSION_GIVEN: 'RMA_PERMISSION_GIVEN',
	RMA_PERMISSION_NOT_GIVEN: 'RMA_PERMISSION_NOT_GIVEN',
};

export enum JobStatusMap {
	UNASSIGNED = 'UNASSIGNED',
	IN_PROGRESS = 'IN_PROGRESS',
	DONE = 'COMPLETED',
	ASSIGNED = 'ASSIGNED',
	CHANGE_REQUESTED = 'CHANGE_REQUESTED',
	SUPERSEDED = 'SUPERSEDED',
	SHADOW = 'SHADOW',
	ON_HOLD = 'ON_HOLD',
	COMPLETED = 'COMPLETED',
	CLOSED = 'CLOSED',
	CANCELLED = 'CANCELLED',
  NOT_APPROVED = 'NOT_APPROVED',
  NOT_STARTED = "NOT_STARTED"
}

export enum ResManPriorityMap {
	PRIORITY_DEFAULT = 'PRIORITY_DEFAULT',
	PRIORITY_LOW = 'PRIORITY_LOW',
	PRIORITY_MEDIUM = 'PRIORITY_MEDIUM',
	PRIORITY_HIGH = 'PRIORITY_HIGH',
}

export const UNASSIGNED_BOARD_ID = '101_000';
export const COMPLETED_BOARD_ID = '101_111';
export const ASSIGNED_ID = '101_222';

const getPrioritySortedValue = (priority?: string): string => {
	switch (priority) {
		case ResManPriorityMap.PRIORITY_HIGH:
			return '0';
		case ResManPriorityMap.PRIORITY_MEDIUM:
			return '1';
		case ResManPriorityMap.PRIORITY_LOW:
			return '2';
		default:
			return '3';
	}
};

const getJobStatusSortedValue = (status?: string): string => {
	switch (status) {
		case JobStatusMap.IN_PROGRESS:
			return '0';
		default:
			return '2';
	}
};

export const onHoldSortedValue = (onHold?: string): string => {
	switch (onHold) {
		case WorkOrderConstants.TRUE:
			return '1';
		default:
			return '0';
	}
};

export const scheduleDateShortedValue = (scheduledDate?: Date): string => {
	if (!scheduledDate) return '1';
	return compareTwoDates(scheduledDate, new Date()) === 0 ? '0' : '1';
};

export const getSortedValue = (
	jobPriority?: string,
	jobStatus?: string,
	jobOnHold?: string,
	jobScheduleDate?: string,
	createdTime?: string
): string => {
	return (
		getJobStatusSortedValue(jobStatus) +
		getPrioritySortedValue(jobPriority) +
		scheduleDateShortedValue(
			!!jobScheduleDate?.trim() && jobScheduleDate !== '-'
				? stringToDate(jobScheduleDate)
				: undefined
		) +
		stringToDate(createdTime).getTime().toString() +
		onHoldSortedValue(jobOnHold)
	);
};

export const ON_HOLD_JOB_ACTION_TITLE =
	'Add details before placing job On Hold.';

export const START_JOB_ACTION_TITLE =
	'Provide details of the work order condition before starting.';
export const FINISH_JOB_ACTION_TITLE =
	'Provide details of the work you performed.';

export const MAINTENANCE_RESPROP = 'Maintenance ResProp';

export const priorityList = [
	WorkOrderConstants.PRIORITY_HIGH,
	WorkOrderConstants.PRIORITY_MEDIUM,
	WorkOrderConstants.PRIORITY_LOW,
];

export const resManAppointmentList = [
	RESMAN_APPOINTMENT_CONSTANT.RMA_CALL,
	RESMAN_APPOINTMENT_CONSTANT.RMA_NOT_REQUIRED,
	RESMAN_APPOINTMENT_CONSTANT.RMA_PERMISSION_GIVEN,
	RESMAN_APPOINTMENT_CONSTANT.RMA_PERMISSION_NOT_GIVEN,
];

export const proofTypes = {
	PRE_COMPLETION: 'PRE_COMPLETION',
	POST_COMPLETION: 'POST_COMPLETION',
	JOB_MIDDLE: 'JOB_MIDDLE',
};
