export const SOLID_ANGLE_RIGHT = "fa-solid fa-angle-right";
export const SOLID_APARTMENT = `fa-solid fa-building`;
export const SOLID_X_MARK = `fa-solid fa-xmark`;
export const SOLID_ANGLE_LEFT = "fa-solid fa-angle-left";
export const SOLID_ARROW_LEFT = `fa-solid fa-arrow-left `;
export const SOLID_ARROW_RIGHT = `fa-solid fa-arrow-right `;
export const SOLID_CHEVRON_RIGHT = "fa-solid fa-chevron-right";
export const SOLID_CHEVRON_LEFT = `fa-solid fa-chevron-left`;
export const SOLID_CHEVRON_DOWN = `fa-solid fa-chevron-down`;
export const SOLID_CHEVRON_UP = `fa-solid fa-chevron-up`;
export const REGULAR_CHEVRON_DOWN = `fa-regular fa-chevron-down`;
export const SOLID_ARROWS_ROTATE = `fa-solid fa-arrows-rotate`;
export const SOLID_PLUS = `fa-solid fa-plus`;
export const SOLID_USER = `fa-solid fa-user`;
export const SOLID_CHECK = `fa-solid fa-check`;
export const SOLID_BALL_PILE = `fa-solid fa-file-invoice`;
export const SOLID_CALENDER_CIRCLE_PLUS = `fi fi-ss-calendar-plus`;
export const SOLID_CALENDER_CLOCK = `fi fi-ss-calendar-clock`;
export const SOLID_CALENDER_CIRCLE_EXCLAMATION = `fi fi-ss-calendar-exclamation`;
export const SOLID_CALENDER = `fi fi-ss-calendar-lines`;
export const SOLID_PEN_TO_SQUARE = "fa-solid fa-pen-to-square";
export const SOLID_HOUSE_USER = `fa-solid fa-house-user`;
export const SOLID_DOOR_CLOSE = `fa-solid fa-door-closed`;
export const SOLID_CLOCK = `fa-solid fa-clock`;
export const SOLID_PHONE = `fa-solid fa-phone`;
export const LIGHT_RIGHT_FROM_BRACKET = `fa-light fa-right-from-bracket`;
export const SOLID_RIGHT_FROM_BRACKET = `fa-solid fa-right-from-bracket`;

export const SOLID_TRASH_CAN = `fa-solid fa-trash-can`;
export const SOLID_CAMERA = `fa-solid fa-camera`;

export const SOLID_CIRCLE = `fa-solid fa-circle`;
export const REGULAR_CIRCLE = `fa-regular fa-circle`;
export const REGULAR_MAGNIFYING_GLASS = `fa-regular fa-magnifying-glass`;
export const SOLID_MAGNIFYING_GLASS = `fa-solid fa-magnifying-glass`;

export const SOLID_CROSS = "fa-solid fa-x";
export const SOLID_HALF_CIRCLE_STROKE = `fa-solid fa-circle-half-stroke`;
export const SOLID_CIRCLE_DASHED = `fa-solid fa-circle-dashed`;
export const SOLID_FLAG = "fa-solid fa-flag";
export const SOLID_FLAG_PENNANT = "fa-solid fa-flag-pennant";

export const SOLID_FLAG_SWALLOWTAIL = `fa-solid fa-flag-swallowtail`;
export const SOLID_CIRCLE_PAUSE = `fa-solid fa-circle-pause`;
export const REGULAR_CIRCLE_PAUSE = `fa-regular fa-circle-pause`;

export const SOLID_LIGHT_BULB = `fa-solid fa-lightbulb`;
export const SOLID_WAREHOUSE = `fa-solid fa-warehouse`;

export const SOLID_X_MARK_LARGE = SOLID_CROSS;
export const SOLID_KANBAN_SQUARE = "fa-brands fa-flipboard";
export const SOLID_CLIPBOARD_CHECK = `fa-solid fa-clipboard-check`;
export const SOLID_GEAR = `fa-solid  fa-gear`;

export const REGULAR_CIRCLE_EXCLAMATION = "fa-regular fa-circle-exclamation";
export const SOLID_CIRCLE_EXCLAMATION = "fa-solid fa-circle-exclamation";
export const SOLID_CIRCLE_CHECK = "fa-solid fa-circle-check";

export const SOLID_CIRCLE_STOP = "fa-solid fa-circle-stop";
