export const categories = [
  {
    name: "Appliances - Ceiling Fan",
    id: "c5f9cf95-1426-49df-a13b-5519a140a63d",
  },
  {
    name: "Appliances - Dishwasher",
    id: "148f8e3-5fea-4885-bc1a-a4f73c2503d4",
  },
  { name: "Appliances - Dryer", id: "3150af91-6077-4846-9231-b56a65bfacbf" },
  {
    name: "Appliances - Microwave",
    id: "3e10bcac-3084-49e0-b167-98d6d1ca3297",
  },
  { name: "Appliances - Oven", id: "c191605a-4069-44f5-aa36-dc1afa755cd9" },
  {
    name: "Appliances - Refrigerator",
    id: "9926ab60-ff9d-45c6-a520-3f1109d9c844",
  },
  {
    name: "Appliances - Stovetop",
    id: "c20b78cd-987a-4f9e-8b27-f2afc5811ba5",
  },
  {
    name: "Appliances - Washing Machine",
    id: "4c6d8edd-bc9e-45a2-99f9-da7a4784e6af",
  },
  { name: "Bath Hardware", id: "65b83576-858b-4760-861b-f4c68fe13bc1" },
  { name: "Blinds", id: "fba0d89e-89f6-479d-b5cf-4afcc8a15f51" },
  { name: "Cabinets", id: "704ae591-275e-4f02-8c3c-fd0429260c36" },
  { name: "Callback", id: "cb3b06ec-69a9-4401-8507-8243bd6940d6" },
  { name: "Clean", id: "6eed71d0-f30c-4658-aa11-7f1d3a8459ec" },
  { name: "Common Area", id: "9d977838-1677-4c84-8011-7f14ad0ba924" },
  { name: "Countertops", id: "bd1d3f56-fe01-4407-b8f1-33e8fd9d0c9a" },
  { name: "Doors", id: "887dfd9a-0f77-4754-9923-8215be902484" },
  {
    name: "Exterior Building Repairs",
    id: "15284f6b-cd3f-45d7-bd10-5426d92a817c",
  },
  { name: "Fireplace", id: "e9d5310b-ac8f-4c92-9d2c-77ed7901847f" },
  { name: "Garage Door", id: "aedeea3d-21a8-4d60-ac03-c0a50d094f0e" },
  { name: "Gates and Fences", id: "125f6923-48a1-41db-b7a2-efe407f8edd0" },
  { name: "Inspection", id: "60bc5aad-3700-42a8-8bc0-fa6febfcea25" },
  { name: "Keys/Locks", id: "650d68c9-b93e-4001-845f-ccc75459d58c" },
  { name: "Landscape", id: "5b9d9b80-a67b-4dec-9a0a-7065b9175ab6" },
  {
    name: "Life Safety or Potential Hazard",
    id: "4f5ca0cc-8c2a-458c-a2c2-1ed4e506200a",
  },
  { name: "Make Ready", id: "766d0468-a742-493d-98a8-a61619b0ca3a" },
  { name: "Moisture Intrusion", id: "c614ecf6-92d9-4962-868f-eeed1766f90c" },
  { name: "Move Out Inspection", id: "abb5e9ff-3ad3-4d34-83d5-000786a153d7" },
  { name: "Online Work Order", id: "d224b41c-8933-4d15-9b08-d7a27fbbf5f2" },
  { name: "Pest Control", id: "a57fa3ea-3e2c-493c-bdff-87e3b14914f3" },
  { name: "Plumbing - Bathtub", id: "c6359863-7010-4849-b2da-6f4315608f66" },
  { name: "Plumbing - Faucet", id: "b9fdf737-06ab-408a-b780-cfc27444eaba" },
  {
    name: "Plumbing - Garbage Disposal",
    id: "afb3b7d8-1da2-4547-a8a1-8d5974eeef68",
  },
  { name: "Plumbing - Shower", id: "edc1c53c-b4b6-490b-b829-f2155631b1f1" },
  { name: "Plumbing - Sink", id: "bee0d92a-2a30-4e2a-960f-65bda59c1e81" },
  { name: "Plumbing - Toilet", id: "86c00e2c-651a-4ab3-a987-67b97732fa79" },
  {
    name: "Plumbing - Water Heater",
    id: "37505dc6-723e-4290-ba3f-3aa4007851b4",
  },
  { name: "Pool Maintenance", id: "8226b53d-9444-4af0-8ba5-cfc0f61aae10" },
  {
    name: "Preventative Maintenance",
    id: "cd08dc75-da77-49a7-bdc7-ae1838434e6a",
  },
  { name: "Quality Inspection", id: "5d0a132a-4987-4362-9419-a6d72596d42f" },
  { name: "Rehab Turn", id: "5af9b956-3fd9-4a36-b0a5-b38a00df9013" },
  { name: "Roof", id: "4005ed4c-2792-451b-8435-f8470a2aee1f" },
  { name: "Suspect Growth", id: "932b2043-61f8-4184-be14-572e8698310c" },
  { name: "Theft/Vandalism", id: "8ef186f9-8476-467b-8112-038d366353a6" },
  { name: "Trashout", id: "a0ad1d1a-3050-479c-9688-08384c8e4313" },
  { name: "Walls/Ceilings", id: "6a5c55ad-5f32-4403-9bbd-1a9f625e7acb" },
  { name: "Window", id: "3356099c-319a-4188-98f6-7b6770439287" },
  { name: "HVAC", id: "d3a23514-44ed-4348-be2b-5d826130487d" },
  { name: "After Hours Call", id: "43d48382-a1da-4930-9889-092d95dd4d1c" },
];
export const areas = [
  "Bathroom 1",
  "Bathroom 2",
  "Bedroom 1",
  "Bedroom 2",
  "Bedroom 3",
  "Hallway",
  "Kitchen",
  "Living Room",
  "Patio",
];
export const pets = ["Bird", "Cat", "Dog"];

export const dummyImages = [
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",

  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",

  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",

  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
];
