import logo from "../../../assets/images/logo-text-icon.svg";
import { FC } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

interface ISuspenseLoader {
  showSpinner?: boolean;
}

const SuspenseLoader: FC<ISuspenseLoader> = ({
  showSpinner = false,
}): JSX.Element => {
  return (
    <div className="loader-wrap-white fixed-top d-flex align-items-center justify-content-center">
      {/* <img className="fallback-logo" src={logo} alt="logo" /> */}

      {showSpinner && (
        <Row className="m-2">
          <Col>
            <Spinner
              as="span"
              animation="grow"
              className="m-1"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <Spinner
              as="span"
              animation="grow"
              className="m-1"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <Spinner
              as="span"
              animation="grow"
              className="m-1"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SuspenseLoader;
