import { TOAST_TYPE } from "../../../constants/GlobalConstants";
import {
  JobStatusMap,
  MAINTENANCE_RESPROP,
  WorkOrderConstants,
} from "../../../constants/WorkOrderConstants";
import { findInArray } from "../../../helpers/utility";
import { Assignee, Job, ProUserProp } from "../../../models/kanabn";
import style from "../Kanban.module.scss";

export const getKanBanIcon = (boardType: string): string => {
  switch (boardType) {
    case "Unassigned":
      return `fa-duotone fa-inboxes icon-left-center ${style.blue15}`;
    case "Completed":
      return `fa-duotone fa-circle-check icon-left-center text-success`;
    case "Cancelled":
      return `fa-duotone fa-ban icon-left-center ${style.red12}`;
    default:
      return `fa-duotone fa-user-astronaut icon-left-center ${style.blue15}`;
  }
};

export const getJobCount = (jobs: Job[], gdv3ReactOnHold: boolean): number => {
  if (!gdv3ReactOnHold) return jobs?.length || 0;
  const unassignedJobs = jobs.filter(
    (job: Job) => gdv3ReactOnHold && job?.isOnHold !== WorkOrderConstants.TRUE,
  );
  return unassignedJobs?.length || 0;
};

export const shouldShowProUser = (
  proUser: ProUserProp,
  propertyId?: string,
) => {
  return (
    proUser?.name !== MAINTENANCE_RESPROP &&
    (propertyId === proUser.propertyId ||
      (typeof proUser.propertyId !== "string" &&
        findInArray(proUser.propertyId, propertyId) > -1))
  );
};

export const getToastTextType = (
  jobStatus: string,
): { title: string; subTitle: string; toastType: string } => {
  let toastType: string = TOAST_TYPE.SUCCESS;
  let title: string = "";
  let subTitle: string = "";
  switch (jobStatus) {
    case JobStatusMap.IN_PROGRESS:
      toastType = TOAST_TYPE.IN_PROGRESS;
      title = "Job Started";
      break;

    case JobStatusMap.DONE:
      toastType = TOAST_TYPE.COMPLETED;
      title = "Job Completed!";
      subTitle = `It has been moved to ‘Closed Work Orders’`;

      break;

    case JobStatusMap.CANCELLED:
      title = "Job Canceled!";
      subTitle = `It has been moved to ‘Closed Work Orders’`;
      toastType = TOAST_TYPE.CANCELED;
      break;

    case JobStatusMap.CLOSED:
      title = "Job Closed!";
      subTitle = `It has been moved to ‘Closed Work Orders’`;
      toastType = TOAST_TYPE.CLOSED;
      break;
    case JobStatusMap.UNASSIGNED:
    case JobStatusMap.ASSIGNED:
      title = "Job Not Started!";
      toastType = TOAST_TYPE.SUCCESS;
      break;
    case "On Hold":
      toastType = TOAST_TYPE.ON_HOLD;
      title = "Job Is Now On Hold!";
      break;
    default:
      title = jobStatus;
      toastType = TOAST_TYPE.SUCCESS;
      break;
  }
  return { toastType, title, subTitle };
};

export const getAssignedProUserName = (
  proUsers: ProUserProp[],
  jobAssignment?: Assignee,
) => {
  const proUserIds = proUsers.map(proUser => proUser.id);
  return !jobAssignment
    ? "Unassigned"
    : jobAssignment.providerId === "" ||
      (jobAssignment?.providerId !== "" &&
        jobAssignment.providerName !== MAINTENANCE_RESPROP &&
        !proUserIds.includes(jobAssignment.providerId))
    ? "Unassigned"
    : jobAssignment.providerName;
};
