import { Job, JobItemReqBody } from "../../models/kanabn";
import {
  getUserId,
  getUserProfileName,
  getUserType,
} from "../../services/authServices";

export const woTransformToAmplitudeEvent = (job: Job) => {
  return {
    job_id: job.id,
    job_property_id: job?.propertyId || "",
    job_status: job?.jobStatus || "",
    user_id: getUserId(),
    user_full_name: getUserProfileName(),
    user_type: getUserType(),
    job_description: job?.description || "",
    job_priority: job?.priority || "",
    job_assignee: job?.jobAssignment?.providerId || "",
    job_reporter: job?.reportedBy || "",
    job_appointment: job?.appointment || "",
    job_appt_date: job?.scheduledDate || "",
    job_appt_time: job?.scheduledTime || "",
    job_unit: job?.unitName || "",
    job_area: JSON.stringify(job?.areas || ""),
    job_category: job?.categoryName || "",
    job_property: job?.propertyName || "",
  };
};

export const woDetailsChangedFieldsTransform = (
  job: Job,
  changedFields: JobItemReqBody,
) => {
  let scheduleData: { [key: string]: any } = {};
  if (changedFields?.scheduledDateTime) {
    scheduleData["scheduledDate"] =
      changedFields.scheduledDateTime.scheduledDate;
    scheduleData["scheduledTime"] =
      changedFields.scheduledDateTime.scheduledTime;
    delete changedFields.scheduledDateTime;
  }
  return {
    ...changedFields,
    ...scheduleData,
    job_id: job.id,
    user_id: getUserId(),
    user_full_name: getUserProfileName(),
    user_type: getUserType(),
  };
};
