import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { ThunkAction } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import loginReducer from "./reducers/loginReducer";
import kanbanBoardReducer from "./reducers/kanbanSlice";
import { KANBAN_BOARD_REDUCER } from "./reducerConstants";

const persistConfig = {
  key: "root",
  storage: storage,

  blacklist: ["kanbanBoardDetails"],
};
const kanBanConfig = {
  key: KANBAN_BOARD_REDUCER,
  storage: storageSession,

  whitelist: [
    "selectedProperties",
    "selectedDateCategory",
    "selectedCategories",
  ],
};

const reducers = combineReducers({
  loginDetails: loginReducer,
  kanbanBoardDetails: persistReducer(kanBanConfig, kanbanBoardReducer),
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // middleware: [thunk],
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof reducers>;

export default store;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
