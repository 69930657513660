/*eslint-disable*/
import axios from 'axios'
import { BFF_API_BASE_URL } from './auth0Credentials'
const axiosInstance = (token = null) => {
  const instance = axios.create()

  instance.defaults.headers.post['Content-Type'] = 'application/json'
  instance.defaults.headers['Accept'] = 'application/json'

  instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  instance.defaults.timeout = 60000
  instance.defaults.baseURL = BFF_API_BASE_URL

  if (!!token) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    const authData = JSON.parse(localStorage.getItem('auth'))
    const token = authData?.accessToken ?? ''
    // consoleLog(token, 'Token')
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }
  instance.interceptors.request.use(
    function (config) {
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      const responseObject = {
        status: response?.data?.statusCode,
        data: response?.data?.data
      }
      return responseObject
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // const errorObject = {
      //   status: error.response === undefined ? 500 : error.response.status,
      //   data:
      //     error.response === undefined
      //       ? 'Network Error'
      //       : error.response.status,
      // };
      const responseObject = {
        status: error.response?.data?.code || 500,
        message: error?.response?.data?.message || 'Something Went Wrong'
      }

      return Promise.reject(responseObject)
    }
  )
  return instance
}
export default axiosInstance
