export enum EventNames {
  SEND_MAGIC_CODE_CLICKED = "Send magic code clicked",
  MAGIC_CODE_ENTERED = "Magic Code Entered",
  JOB_EDIT_MODE_CLICKED = "Job Edit Mode Clicked",
  NAVIGATION_CLICKED = "Navigation Clicked",
  JOB_EDIT_SAVE_CLICKED = "Job Edit Save Clicked",
  LOGOUT_CLICKED = "Log Out Clicked",
  JOB_ASSIGNEE_DRAG_N_DROP = "Job Assigned by DragDrop",
  JOB_START_CLICK = "Job Start Click",
  JOB_FINISH_CLICK = "Job Finish Click",
  JOB_ON_HOLD_CLICK = "Job Hold Click",
  JOB_CLOSED_CLICK = "Job Close Click",
  JOB_CANCEL_CLICK = "Job Cancel Click",
  JOB_NOT_STARTED_CLICK = "Job Not Started Clicked",
  CREATE_WO_CLICK = "Create New Clicked",
  PROPERTY_FILTER_APPLIED = "Property Filter Used",
  PRO_WO_ASSIGNED_NOTIFICATION_CHANGED = "Pro WO Assigned Notification Changed",
  PHONE_NO_CLICKED = "Phone No Clicked",
  SEARCH_USED = "Search Used",
  UPLOAD_PHOTO_OPENED = "Upload Photo Opened",
  PHOTO_UPLOADED = "Photo Uploaded",
  CAMERA_OPENED = "Camera Opened",
  PHOTO_TAKEN = "Photo Taken",
  REFRESH_CLICKED = "Refresh Clicked",
  FILTER_RESET_CLICKED = "Filter Reset Clicked",
  CATEGORY_FILTER_RESET_CLICKED = "Category Filter Reset Clicked",
  PROPERTY_FILTER_RESET_CLICKED = "Property Filter Reset Clicked",
  DATE_FILTER_RESET_CLICKED = "Date Filter Reset Clicked",
  STATUS_FILTER_RESET_CLICKED = "Status Filter Reset Clicked",
  JOB_CREATE_CLICKED = "Job Create Clicked",
  QUICK_PRIORITY_CHANGE = "Quick Priority Change",
  QUICK_ASSIGNEE_CHANGE = "Quick Assignee Change",
  ADD_APP_SHORTCUT_CLICKED = "Add App Shortcut Clicked",
  ADD_APP_SHORTCUT_CLOSED = "Add App Shortcut Closed",
  CATEGORY_FILTER_USED = "Category Filter Used",
  DATE_FILTER_USED = "Date Filter Used",
  STATUS_FILTER_USED = "Status Filter Used",
  MAKE_READY_CLICKED = "Make Ready Tab Clicked",
}

export enum EventNamesFromStatus {
  ON_HOLD = "JOB_ON_HOLD_CLICK",
  DONE = "JOB_FINISH_CLICK",
  IN_PROGRESS = "JOB_START_CLICK",
  CANCELLED = "JOB_CANCEL_CLICK",
  APPROVED = "JOB_CLOSED_CLICK",
  UNASSIGNED = "JOB_NOT_STARTED_CLICK",
  ASSIGNED = "JOB_NOT_STARTED_CLICK",
}
