import { FILE_BASE_URL, PUBLIC_URL } from "./configs/credentials";
import consoleLog from "./consoleLog";

import * as _ from "lodash";
export const findInArray = (
  array: any[] = [],
  itemToFind: any,
  key: string = ""
): number => {
  return array.findIndex((item) =>
    !!key.trim() ? item[key] === itemToFind : item === itemToFind
  );
};

export const getToken = (): string => {
  const storage = JSON.parse(localStorage.getItem("persist:root") || "");
  return storage?.loginDetails
    ? JSON.parse(storage.loginDetails)?.auth?.accessToken
    : "";
};

export const isDefined = (
  object: { [key: string]: any },
  property: any = null
) => {
  if (property === null) {
    return typeof object !== "undefined";
  }

  return (
    typeof object !== "undefined" &&
    object &&
    typeof object[property] !== "undefined"
  );
};

export const stringToDate = (date = "") => {
  if (!date.trim()) return new Date();
  return new Date(date);
};

export const compareTwoDates = (date1: Date, date2: Date): number => {
  let firstDate = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );

  let secondDate = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );
  if (firstDate.getTime() < secondDate.getTime()) {
    return -1;
  } else if (firstDate.getTime() > secondDate.getTime()) {
    return 1;
  } else {
    return 0;
  }
};

export const compareTwoDatesTime = (date1: Date, date2: Date): number => {
  let firstDate = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    date1.getHours(),
    date1.getMinutes()
  );

  let secondDate = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
    date2.getHours(),
    date2.getMinutes()
  );
  if (firstDate.getTime() < secondDate.getTime()) {
    return -1;
  } else if (firstDate.getTime() > secondDate.getTime()) {
    return 1;
  } else {
    return 0;
  }
};

export const getLocalizedTimestamp = (timestamp: string) => {
  const dateFromServer = new Date(timestamp);
  const serverOffset = 480; // in minutes, from that API call
  const serverOffsetMillis = 60 * 1000 * serverOffset;
  const localOffset = new Date().getTimezoneOffset(); // in minutes
  const localOffsetMillis = 60 * 1000 * localOffset;
  const localTimestamp = new Date(
    dateFromServer.getTime() - serverOffsetMillis + localOffsetMillis
  );
  return localTimestamp;
};

export const getImageSizeAndTypeFromBase64 = (
  base64String: string
): { size: number; type: string } => {
  let base64Length = base64String.length - (base64String.indexOf(",") + 1);
  let padding =
    base64String.charAt(base64String.length - 2) === "="
      ? 2
      : base64String.charAt(base64String.length - 1) === "="
      ? 1
      : 0;
  let fileSize = base64Length * 0.75 - padding;

  const type = base64String.split(";")[0].split(":")[1];
  return { size: fileSize, type };
};

export const getBase64 = async (file: any): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        reject(error);
      };
    } catch (error) {
      reject(error);
    }
  });
};
export const dataUrlToFile = async (
  dataUrl: string,
  fileName: string = new Date().toString()
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
};

export const convertFileIntoMultipleFormat = async (
  sourceFile: any
): Promise<{ file: File; base64: string; type: string; size: number }> => {
  let file: File;
  let base64: string;
  if (typeof sourceFile === "string") {
    file = await dataUrlToFile(sourceFile);
    base64 = sourceFile;
  } else {
    file = sourceFile;
    base64 = await getBase64(sourceFile);
  }

  return { file, base64, type: file.type, size: file.size };
};

export const getFile = (fileName: string) => {
  return `${FILE_BASE_URL}${fileName}`;
};

export const getMakeReadyBackground = () => {
  return `url(${PUBLIC_URL + "/img/make-ready-background.png"})`;
};

export const stringTruncate = (str: string, n: number): string => {
  return str.length > n ? str.substring(0, n - 1) + " ...." : str;
};

export const timeSince = (
  dateToCompare: Date,
  dateToCompareWith?: Date
): string => {
  const dteToCompareWith: Date = !!dateToCompareWith
    ? dateToCompareWith
    : new Date();
  const dateDiff: number = dteToCompareWith.getTime() - dateToCompare.getTime();
  let seconds = Math.floor(dateDiff / 1000);

  const days = seconds / 86400;
  const hours = seconds / 3600;
  const minutes = seconds / 60;

  let dateString: string = "";
  if (days >= 1) {
    dateString = `${Math.floor(days)} days`;
  } else if (hours >= 1 && hours < 24) {
    dateString = Math.floor(hours) + " hr";
  } else if (minutes >= 1 && minutes <= 59) {
    dateString = Math.floor(minutes) + " min";
  } else if (minutes < 1) {
    dateString = Math.floor(seconds) + " sec";
  }
  return dateString;
};

export const isGraterThanOneHour = (
  dateToCompare: Date,
  dateToCompareWith?: Date
): boolean => {
  const dteToCompareWith: Date = !!dateToCompareWith
    ? dateToCompareWith
    : new Date();
  const dateDiff: number = dteToCompareWith.getTime() - dateToCompare.getTime();
  let seconds = Math.floor(dateDiff / 1000);
  const minutes = seconds / 60;
  return minutes > 59;
};

export const getUserShortName = (name: string) => {
  const arr = name.split(" ");
  const secondLater = arr?.[1]?.[0] ? ` ${arr[1][0]}` : "";
  return arr[0] + secondLater;
};

export const getPreviousDateByDay = (days: number): Date => {
  const currentTime = new Date();
  let lastDate = new Date(currentTime.getTime() - days * 24 * 60 * 60 * 1000);
  lastDate.setUTCHours(0, 0, 0, 0);
  return lastDate;
};

export const getCurrentDateLastTime = (): Date => {
  const currentDate = new Date();
  currentDate.setUTCHours(23, 59, 59, 999);
  return currentDate;
};

export const getNextDate = (date: Date): Date => {
  const currentDate = date;
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setUTCHours(0, 0, 0, 0);
  return currentDate;
};

export const getPreviousDate = (date: Date): Date => {
  const currentDate = date;
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate.setUTCHours(23, 59, 59, 999);
  return currentDate;
};

export const deepClone = (arg: any): any => {
  return _.cloneDeep(arg);
};

export const isTwoArrayEqual = (array1: any[], array2: any[]): boolean => {
  if (array1.length !== array2.length) return false;

  return array1.every((a: any) => findInArray(array2, a) > -1);
};

export const isEmpty = (value: any): boolean => {
  return _.isEmpty(value);
};

// Get pagination info
export const getPaginationInfo = ({
  pageNumber,
  pageSize,
  totalItems,
}: {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
}): {
  startIndex: number;
  endIndex: number;
} => {
  // Calculate start and end indexes of items on the current page
  const startIndex = (pageNumber - 1) * pageSize + 1;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems);

  return {
    startIndex,
    endIndex,
  };
};
