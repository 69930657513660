import { isGraterThanOneHour, timeSince } from "../helpers/utility";

const saveLastRefreshedTime = () => {
  localStorage.setItem("refreshTime", new Date().toString());
};

const refreshTimeDiff = (): {
  timeDiff: string;
  isGreaterThanAnHour: boolean;
} => {
  const currentTime: Date = new Date();
  const lcLastRefreshTime: string =
    localStorage?.getItem("refreshTime") || new Date().toString();
  const lastRefreshTime: Date = new Date(lcLastRefreshTime);
  return {
    timeDiff: timeSince(lastRefreshTime, currentTime),
    isGreaterThanAnHour: isGraterThanOneHour(lastRefreshTime, currentTime),
  };
};

const saveExpirationForCancelledPwaPopUp = () => {
  var date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
  localStorage.setItem("expirationDatePwaPopUp", date.getTime().toString());
};

const isPwaPopIsExpired = (): boolean => {
  const expirationDatePwaPopUp: string | undefined | null =
    localStorage?.getItem("expirationDatePwaPopUp");

  if (!expirationDatePwaPopUp) return true;

  const date = new Date(expirationDatePwaPopUp);
  return new Date().getTime() > date.getTime();
};

const commonServices = {
  saveLastRefreshedTime,
  refreshTimeDiff,
  saveExpirationForCancelledPwaPopUp,
  isPwaPopIsExpired,
};
export default commonServices;
