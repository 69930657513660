import * as amplitude from "@amplitude/analytics-browser";
import { EventNames } from "../constants/AmplitudeEvent";
import { UNASSIGNED_BOARD_ID } from "../constants/WorkOrderConstants";
import { AMPLITUDE_API_KEY } from "../helpers/configs/amplitudeCredentials";
import consoleLog from "../helpers/consoleLog";
import {
  woDetailsChangedFieldsTransform,
  woTransformToAmplitudeEvent,
} from "../helpers/transformers/woTransformers";
import { findInArray } from "../helpers/utility";
import {
  Category,
  DateCategory,
  FilterStatus,
  Job,
  JobItemReqBody,
  Property,
} from "../models/kanabn";
import { getUserId, getUserProfileName, getUserType } from "./authServices";

const USER_ID = "user_id";

const init = () => {
  amplitude.init(AMPLITUDE_API_KEY);
};

const trackAmplitude = (
  eventName: EventNames,
  uniqueId: string,
  properties: Object,
  uniqIdName: string,
) => {
  if (!!uniqueId.trim()) {
    amplitude.track(
      eventName,
      {
        ...properties,
      },
      { [uniqIdName]: uniqueId },
    );
  }
};
const identifyAmplitude = (
  uniqueId: string,
  uniqIdName: string,
  identifyObj: amplitude.Identify,
  extra: { [key: string]: any },
) => {
  for (const extraField of Object.keys(extra)) {
    identifyObj.set(extraField, extra[extraField]);
  }

  amplitude.identify(identifyObj, {
    [uniqIdName]: uniqueId,
  });
};

const jobEditModeClickedEventFor = ({ job }: { job: Job }) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("property", job?.propertyName || "");
  identifyAmplitude(userId, USER_ID, identify, {});

  trackAmplitude(
    EventNames.JOB_EDIT_MODE_CLICKED,
    userId,
    {
      ...woTransformToAmplitudeEvent(job),
    },
    USER_ID,
  );
};

const jobEditSaveClickedEventFor = ({
  job,
  isSaveSuccessful,
  changedFields,
}: {
  job: Job;
  isSaveSuccessful: boolean;
  changedFields: JobItemReqBody;
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("property", job?.propertyName || "");
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.JOB_EDIT_SAVE_CLICKED,
    userId,
    {
      ...woDetailsChangedFieldsTransform(job, changedFields),
      status: isSaveSuccessful,
    },
    USER_ID,
  );
};

const sendMagicCodeEventFor = ({
  contactNumber,
  isMagicCodeSendSuccessful,
}: {
  contactNumber: string;
  isMagicCodeSendSuccessful: boolean;
}) => {
  const identify = new amplitude.Identify();
  identify.set("phone", contactNumber);
  identifyAmplitude(contactNumber, "phone", identify, {});
  trackAmplitude(
    EventNames.SEND_MAGIC_CODE_CLICKED,
    contactNumber,
    { status: isMagicCodeSendSuccessful ? "true" : "false" },
    "phone",
  );
};

const magicCodeEnteredEventFor = ({
  contactNumber,
}: {
  contactNumber: string;
}) => {
  const identify = new amplitude.Identify();
  identify.set("phone", contactNumber);
  identifyAmplitude(contactNumber, "phone", identify, {});
  trackAmplitude(
    EventNames.MAGIC_CODE_ENTERED,
    contactNumber,
    { isMagicCodeEntered: true },
    "phone",
  );
};

const navigationChangeEventFor = ({ pathName }: { pathName: string }) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.NAVIGATION_CLICKED,
    userId,
    { pathName: pathName },
    USER_ID,
  );
};

const logOutClickedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.LOGOUT_CLICKED,
    userId,
    { user_id: userId },
    USER_ID,
  );
};

const jobAssignmentDragNDropEventFor = ({
  job,
  previousAssignee,
  currentAssignee,
}: {
  job: Job;
  previousAssignee: {
    id: string;
    name: string;
  };
  currentAssignee: {
    id: string;
    name: string;
  };
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identify.set("property", job?.propertyName || "");
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.JOB_ASSIGNEE_DRAG_N_DROP,
    userId,
    {
      user_id: userId,
      job_id: job.id,
      previousAssigneeId:
        previousAssignee.id === UNASSIGNED_BOARD_ID ? "" : previousAssignee.id,
      previousAssigneeName: previousAssignee.name,
      assigneeId:
        currentAssignee.id === UNASSIGNED_BOARD_ID ? "" : currentAssignee.id,
      assigneeName: currentAssignee.name,
    },
    USER_ID,
  );
};

const jobStatusChangeEventFor = ({
  job,
  previousStatus,
  enumType,
}: {
  job: Job;
  previousStatus: string;
  enumType: string;
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames[enumType as keyof typeof EventNames],
    userId,
    {
      job_id: job.id,
      previous_status: previousStatus,
      job_property: job.propertyName,
      job_unit: job.unitName,
      job_description: job.description,
    },
    USER_ID,
  );
};

const createWOClickEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.CREATE_WO_CLICK,
    userId,
    { user_id: userId },
    USER_ID,
  );
};

const propertyFilterAppliedEventFor = ({
  properties_selected,
  properties,
}: {
  properties_selected: string[];
  properties: Property[];
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.PROPERTY_FILTER_APPLIED,
    userId,
    {
      user_id: userId,
      properties_selected: properties_selected.length,
      property_names: JSON.stringify(
        properties_selected.map(
          (propertyId: string) =>
            properties.filter(
              (property: Property) => property.id === propertyId,
            )[0].name,
        ),
      ),
    },
    USER_ID,
  );
};

const proWOAssignedNotificationChangedEventFor = ({
  changed_to,
}: {
  changed_to: boolean;
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.PRO_WO_ASSIGNED_NOTIFICATION_CHANGED,
    userId,
    { user_id: userId, changed_to: changed_to ? "on" : "off" },
    USER_ID,
  );
};

const phoneNoClickedEventFor = ({ job }: { job: Job }) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.PHONE_NO_CLICKED,
    userId,
    { user_id: userId, job_id: job.id, job_status: job.jobStatus },
    USER_ID,
  );
};

const searchUsedEventFor = ({
  searchPage,
  searchTerm,
}: {
  searchTerm: string;
  searchPage: string;
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.SEARCH_USED,
    userId,
    { user_id: userId, search_term: searchTerm, search_page: searchPage },
    USER_ID,
  );
};

const cameraOpenedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.CAMERA_OPENED,
    userId,
    { user_id: userId },
    USER_ID,
  );
};

const photoTakenEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(EventNames.PHOTO_TAKEN, userId, { user_id: userId }, USER_ID);
};

const refreshClickedEventFor = ({
  lastRefreshTime,
}: {
  lastRefreshTime: string;
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.REFRESH_CLICKED,
    userId,
    { user_id: userId, refresh_timer: lastRefreshTime },
    USER_ID,
  );
};

const uploadPhotoOpenedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.UPLOAD_PHOTO_OPENED,
    userId,
    { user_id: userId },
    USER_ID,
  );
};

const photoUploadedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.PHOTO_UPLOADED,
    userId,
    { user_id: userId },
    USER_ID,
  );
};

const filterResetClickedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.FILTER_RESET_CLICKED,
    userId,
    { user_id: userId },
    USER_ID,
  );
};

const jobCreateClickedEventFor = ({
  data,
}: {
  data: { [key: string]: any };
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.JOB_CREATE_CLICKED,
    userId,
    { user_id: userId, ...data },
    USER_ID,
  );
};

const quickPriorityChangeEventFor = ({
  job,
  newPriority,
}: {
  job: Job;
  newPriority: string;
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.QUICK_PRIORITY_CHANGE,
    userId,
    {
      user_id: userId,
      job_id: job.id,
      new_value: newPriority,
      previous_value: job.priority,
    },
    USER_ID,
  );
};

const quickAssigneeChangeEventFor = ({
  job,
  newJobAssignment,
}: {
  job: Job;
  newJobAssignment: {
    providerId: string;
    providerName: string;
  };
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.QUICK_ASSIGNEE_CHANGE,
    userId,
    {
      user_id: userId,
      job_id: job.id,
      new_value: JSON.stringify({
        providerId: newJobAssignment.providerId,
        providerName: newJobAssignment.providerName,
      }),
      previous_value: JSON.stringify({
        providerId: job.jobAssignment?.providerId,
        providerName: job.jobAssignment?.providerName,
      }),
    },
    USER_ID,
  );
};

const addAppShortcutCLickedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.ADD_APP_SHORTCUT_CLICKED,
    userId,
    {
      user_id: userId,
    },
    USER_ID,
  );
};

const addAppShortcutClosedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.ADD_APP_SHORTCUT_CLOSED,
    userId,
    {
      user_id: userId,
    },
    USER_ID,
  );
};

const categoryFilterUsedEventFor = ({
  category_selected,
  categories,
}: {
  category_selected: string[];
  categories: Category[];
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.CATEGORY_FILTER_USED,
    userId,
    {
      user_id: userId,
      categories_selected: category_selected.length,
      category_names: JSON.stringify(
        category_selected.map(
          (categoryId: string) =>
            categories.filter(
              (category: Category) => category.id === categoryId,
            )[0].name,
        ),
      ),
    },
    USER_ID,
  );
};

const statusFilterUsedEventFor = ({
  status_category_selected,
  status_categories,
}: {
  status_category_selected: string[];
  status_categories: FilterStatus[];
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.STATUS_FILTER_USED,
    userId,
    {
      user_id: userId,
      status_selected: status_category_selected.length,
      status_names: JSON.stringify(
        status_category_selected.map(
          (categoryId: string) =>
            status_categories.filter(
              (category: Category) => category.id === categoryId,
            )[0].name,
        ),
      ),
    },
    USER_ID,
  );
};

const dateFilterUsedEventFor = ({
  date_categories_selected,
  date_categories,
}: {
  date_categories_selected: string[];
  date_categories: DateCategory[];
}) => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  const dateName: string = date_categories.filter(
    d => findInArray(date_categories_selected, d.id) > -1,
  )[0].name;
  trackAmplitude(
    EventNames.DATE_FILTER_USED,
    userId,
    {
      user_id: userId,
      date_filter_name: dateName,
    },
    USER_ID,
  );
};

const makeReadyClickedEventFor = () => {
  const userId = getUserId();
  const identify = new amplitude.Identify();
  identify.set("userId", userId);
  identifyAmplitude(userId, USER_ID, identify, {});
  trackAmplitude(
    EventNames.MAKE_READY_CLICKED,
    userId,
    {
      user_id: userId,
    },
    USER_ID,
  );
};

const amplitudeService = {
  init,
  jobEditModeClickedEventFor,
  jobEditSaveClickedEventFor,
  sendMagicCodeEventFor,
  magicCodeEnteredEventFor,
  navigationChangeEventFor,
  logOutClickedEventFor,
  jobAssignmentDragNDropEventFor,
  jobStatusChangeEventFor,
  createWOClickEventFor,
  propertyFilterAppliedEventFor,
  proWOAssignedNotificationChangedEventFor,
  phoneNoClickedEventFor,
  searchUsedEventFor,
  cameraOpenedEventFor,
  photoTakenEventFor,
  refreshClickedEventFor,
  uploadPhotoOpenedEventFor,
  photoUploadedEventFor,
  filterResetClickedEventFor,
  jobCreateClickedEventFor,
  quickPriorityChangeEventFor,
  quickAssigneeChangeEventFor,
  addAppShortcutCLickedEventFor,
  addAppShortcutClosedEventFor,
  categoryFilterUsedEventFor,
  statusFilterUsedEventFor,
  dateFilterUsedEventFor,
  makeReadyClickedEventFor,
};
export default amplitudeService;
