import { createSlice } from "@reduxjs/toolkit";
import authServices from "../../services/authServices";
import { logOutThunk } from "../actions/auth/authThunks";
import { LOGIN_DETAILS_REDUCER } from "../reducerConstants";

interface LoginState {
  isLoggedIn: boolean;
  auth: Object;
  isLoading: boolean;
}

const initialState: LoginState = {
  isLoggedIn: false,
  auth: {},
  isLoading: false,
};

const loginDetails = createSlice({
  name: LOGIN_DETAILS_REDUCER,
  initialState,
  reducers: {
    logIn: state => {
      state.isLoggedIn = true;
    },
    logOut: state => {
      authServices.logOut();
      state.auth = {};
      state.isLoggedIn = false;
    },
    setAuthData: (state, { payload }) => {
      state.auth = {
        ...payload?.authData,
      };
      state.isLoggedIn = true;
    },
  },
  extraReducers: builder => {
    // builder.addCase(getAccessTokenThunk.fulfilled, (state, { payload }) => {
    //   // state.kanbanBoardData = payload;?
    //   state.isLoggedIn = true;
    //   state.auth = { ...payload?.authData };
    //   state.isLoading = false;
    // });
    // builder.addCase(getAccessTokenThunk.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getAccessTokenThunk.rejected, (state, action) => {
    //   state.isLoading = false;
    // });
    builder.addCase(logOutThunk.fulfilled, (state, action) => {
      state.auth = {};
      state.isLoggedIn = false;
    });
  },
});

export const { logIn, logOut, setAuthData } = loginDetails.actions;

export default loginDetails.reducer;
