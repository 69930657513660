export const RouterConstants = {
  KANBAN: "/kanban",
  LOGIN: "/login",
  VERIFY_OTP: "/login/verify-otp",
  COMPLETED_WORK_ORDERS: "/completed-work-orders",
  DUMMY: "/dummy",
  NOT_FOUND: "*",
  SETTINGS: "/settings",
  MAKE_READY: "/make-ready",
  DASHBOARD: "/dashboard",
};
