import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	JobStatusMap,
	MAINTENANCE_RESPROP,
	UNASSIGNED_BOARD_ID,
} from '../../../constants/WorkOrderConstants';
import consoleLog from '../../../helpers/consoleLog';
import { sortBoardData, sortPropertiesByName } from '../../../helpers/utils';
import {
	BoardProp,
	Category,
	Job,
	Property,
	ProUserProp,
} from '../../../models/kanabn';
import { IMoveBoardPayload } from '../../../models/PayloadModels';
import { IJobsProps } from '../../../models/PromiseModels';
import {
	getUserAccountId,
	getUserProfileName,
	hasFullAccess,
} from '../../../services/authServices';
import kanBanServices from '../../../services/kanbanServices';
import { KANBAN_BOARD_REDUCER } from '../../reducerConstants';
import { changeCurrentProperty } from '../../reducers/kanbanSlice';
import { RootState } from '../../store';

const fetchJobsFullAccessUser = ({
	selected_property_ids,
	properties,
}: {
	selected_property_ids: string | string[];
	properties: Property[];
	categoryIds: string[];
}): Promise<{ wo: BoardProp[]; pros: ProUserProp[] }> => {
	return new Promise(async (resolve, reject) => {
		try {
			let wo: BoardProp[] = [];
			let unassignedJobs: Job[] = [];
			const pros: ProUserProp[] = await kanBanServices.getProUsersForBoard(
				selected_property_ids
			);

			wo.push({
				boardName: 'Unassigned',
				boardId: UNASSIGNED_BOARD_ID,
				propertyId: properties.map((p) => p.id),
				jobs: sortBoardData(unassignedJobs),
			});
			for (let index = 0; index < pros.length; index++) {
				const user: ProUserProp = pros[index];
				if (user.name === MAINTENANCE_RESPROP) {
					continue;
				}
				let jobOfUser: Job[] = [];
				wo.push({
					boardName: user.name,
					boardId: user.id,
					propertyId: user.propertyId,
					jobs: sortBoardData(jobOfUser),
				});
			}
			resolve({ wo: wo, pros: pros });
		} catch (error) {
			reject(error);
		}
	});
};

const fetchJobsLimitedAccessUser = ({
	selected_property_ids,
	dateCategories,
	statusCategories,
	categoryIds,
}: {
	selected_property_ids: string[] | string;
	dateCategories?: string[];
	statusCategories?: string[];
	categoryIds: string[];
}): Promise<{ wo: BoardProp[]; pros: ProUserProp[] }> => {
	return new Promise(async (resolve, reject) => {
		try {
			let boardData: any = [];
			let wo: BoardProp[] = [];

			const pros: ProUserProp[] = await kanBanServices.getProUsersForBoard(
				selected_property_ids
			);

			wo.push({
				boardId: getUserAccountId(),
				boardName: getUserProfileName(),
				propertyId: 'Assigned',
				jobs: [],
			});
			resolve({ wo: wo, pros: pros });
		} catch (error) {
			reject(error);
		}
	});
};

export const fetchKanbanBoardData = createAsyncThunk(
	`${KANBAN_BOARD_REDUCER}/fetchKanbanBoardData`,
	async (
		payload: {
			propertyIds: string[] | string;
			categoryIds?: string[];
			selectedDateCategory?: string[];
			selectedStatusFilters?: string[];
		},
		{ rejectWithValue, dispatch, getState }
	) => {
		try {
			const {
				propertyIds,
				categoryIds = [],
				selectedDateCategory,
				selectedStatusFilters,
			} = payload;
			const state = getState() as RootState;
			const properties: Property[] = state.kanbanBoardDetails.properties;
			const { wo, pros } = hasFullAccess()
				? await fetchJobsFullAccessUser({
						selected_property_ids: propertyIds,
						properties,
						categoryIds,
				  })
				: await fetchJobsLimitedAccessUser({
						selected_property_ids: propertyIds,
						categoryIds: categoryIds,
						dateCategories: selectedDateCategory,
						statusCategories: selectedStatusFilters,
				  });
			return { workOrders: wo, proUsers: pros };
		} catch (error) {
			consoleLog(error);
			rejectWithValue(error);
		}
	}
);

export const getPropertiesThunks = createAsyncThunk(
	`${KANBAN_BOARD_REDUCER}/fetchProperties`,
	async (payload, { rejectWithValue, dispatch }) => {
		try {
			const response = await kanBanServices.getProperties();

			const properties = Array.isArray(response) ? response : [];
			return {
				properties: properties.sort(sortPropertiesByName),
			};
		} catch (error) {
			rejectWithValue(error);
		}
	}
);

export const getCategoriesThunks = createAsyncThunk(
	`${KANBAN_BOARD_REDUCER}/fetchCategories`,
	async (payload, { rejectWithValue, dispatch }) => {
		try {
			const { categories }: { categories: Category[] } =
				await kanBanServices.getCategories();

			return {
				categories: categories ?? [],
			};
		} catch (error) {
			rejectWithValue(error);
		}
	}
);

export const changePropertyThunks = createAsyncThunk(
	`${KANBAN_BOARD_REDUCER}/changePropertyThunks`,
	async (currentProperty: Property, { rejectWithValue, dispatch }) => {
		dispatch(changeCurrentProperty({ currentProperty }));
		// dispatch(fetchKanbanBoardData(currentProperty?.id));
	}
);

export const moveKanbanBoardItemThunks = createAsyncThunk(
	`${KANBAN_BOARD_REDUCER}/moveKanbanBoardItemThunks`,
	async (
		payload: IMoveBoardPayload,
		{ rejectWithValue, dispatch, getState }
	) => {
		const { source, destination } = payload;
	}
);
