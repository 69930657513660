import * as auth0 from 'auth0-js'
import {
  CHECK_USER,
  LOGIN,
  LOGIN_LIST_ROLES,
  USER_INFORMATION
} from '../helpers/apis/apis'
import { AUTH_DOMAIN, CLIENT_REF_ID } from '../helpers/configs/auth0Credentials'
import { apiGet, apiPost } from '../helpers/configs/axiosConfig'
import consoleLog from '../helpers/consoleLog'

const FULL_ACCESS_USER = 'FULL_ACCESS_USER'
const LIMITED_ACCESS_USER = 'LIMITED_ACCESS_USER'
const PRO = 'Pro_User'
const PROP_MGMR = 'Pro_Manager'
const ADMIN = 'System'
class AuthInfo {
  expiresAt
  userProfile
  accessToken
  accessPayload
  authenticated = false
  isUserFound = false
}

let authData = {}

const logOut = () => {
  let authData = {}
  authData.authenticated = false
  authData.accessToken = null
  localStorage.clear()
  authData.isUserNotFound = false
  localStorage.setItem('userProfile', JSON.stringify({}))
  localStorage.setItem('auth', JSON.stringify(authData))
}

function _setSession (authResult) {
  authData = new AuthInfo()
  authData.expiresAt = authResult.exp * 1000
  authData.accessToken = authResult.idToken
  authData.authenticated = true
  localStorage.setItem('auth', JSON.stringify(authData))
}

export const getUserRoles = async () => {
  try {
    const user = await apiGet({
      apiPath: USER_INFORMATION
    })

    const roles = user?.roles ?? []

    localStorage.setItem(
      'role',
      roles.includes(PROP_MGMR) || roles.includes(ADMIN)
        ? FULL_ACCESS_USER
        : LIMITED_ACCESS_USER
    )
    localStorage.setItem('userProfile', JSON.stringify(user))
    consoleLog(user)
  } catch (error) {
    consoleLog(error)
  }
}

export const checkUser = async ({ number }) => {
  return await apiPost({
    apiPath: CHECK_USER,
    config: {
      headers: {
        'Content-Type': 'application/json'
      }
    },
    data: {
      contact: number
    }
  })
}
export function isLoggedIn () {
  authData = JSON.parse(localStorage.getItem('auth'))
  return authData?.accessToken && Date.now() < authData?.expiresAt
}

export const hasFullAccess = () => {
  const userRole = localStorage.getItem('role')
  return userRole === FULL_ACCESS_USER
}

export function getUserData () {
  return JSON.parse(localStorage.getItem('auth'))
}

export const getUserAccountId = () => {
  const userProfile = localStorage?.getItem('userProfile')
    ? JSON.parse(localStorage?.getItem('userProfile'))
    : {}

  return userProfile?.accountId ?? ''
}

export const getUserProfile = () => {
  const userProfile = localStorage?.getItem('userProfile')
    ? JSON.parse(localStorage?.getItem('userProfile'))
    : {}
  return userProfile ?? {}
}

export const getUserProfileName = () => {
  return getUserProfile()?.firstName
    ? `${getUserProfile()?.firstName ?? ''} ${getUserProfile()?.lastName ?? ''}`
    : getUserProfile()?.accountName ?? ''
}

export const hasUserProRole = () => {
  const userProfile = getUserProfile()
  const roles = userProfile?.roles ?? []
  return !!roles.includes(PRO)
}

export const getUserId = () => {
  const userProfile = JSON.parse(localStorage?.getItem('userProfile'))
  return userProfile?.id ?? ''
}

export const getUserType = () => {
  return hasFullAccess() ? FULL_ACCESS_USER : LIMITED_ACCESS_USER
}

export const login = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiPost({
        apiPath: LOGIN,
        data: {
          email,
          password
        }
      })
      _setSession({
        exp: data.expiresIn,
        idToken: data.token
      })
      resolve(data)
    } catch (error) {
      consoleLog(error)
      reject(error)
    }
  })
}

const authServices = {
  logOut,
  login
}

export default authServices
