import { DEFAULT_DATE } from "../constants/GlobalConstants";
import {
  JobStatusMap,
  MAINTENANCE_RESPROP,
  WorkOrderConstants,
} from "../constants/WorkOrderConstants";
import {
  FIND_JOBS,
  GET_PROPERTIES,
  GET_PRO_USERS,
  GET_JOB_ITEM,
  FIND_JOBS_PRO,
  POST_PRO_USERS,
  GET_PROPERTIES_UNITS,
  GET_CATEGORIES,
} from "../helpers/apis/apis";
import { apiGet, apiPost, apiPut } from "../helpers/configs/axiosConfig";
import consoleLog from "../helpers/consoleLog";
import { getAllWorkOrdersCategory } from "../helpers/transformers/woCategoryTransformets";
import {
  findInArray,
  getCurrentDateLastTime,
  getNextDate,
  getPreviousDate,
  getPreviousDateByDay,
} from "../helpers/utility";
import { mapJobStatuses } from "../helpers/utility/workOrdersUtils";
import { sortProUserByName } from "../helpers/utils";
import { Category, Job, ProUserProp } from "../models/kanabn";
import { IJobsProps } from "../models/PromiseModels";
import store from "../redux/store";
import { getUserAccountId } from "./authServices";

const addSearchCriterias = ({
  postData = {},
  searchCriteria = "",
  categoryIds,
  statusCategories,
  dateCategories,
  pageSize,
  offset,
  propertyIds,
  isFromAssignedColumn = false,
}: {
  searchCriteria?: string;
  propertyIds?: string | string[];
  categoryIds?: string[];
  pageSize?: number;
  offset?: number;
  dateCategories?: string[];
  statusCategories?: string[];
  postData: { [key: string]: any };
  isFromAssignedColumn?: boolean;
}): { [key: string]: any } => {
  const categoryLists: Category[] =
    store.getState().kanbanBoardDetails.categories;

  const allWoCategories = getAllWorkOrdersCategory(categoryLists);

  let isAllWorkOrderSelected = allWoCategories.every(
    (category: Category) => findInArray(categoryIds, category.id) > -1
  );

  if (!!propertyIds) {
    postData["propertyIds"] = propertyIds;
  }
  if (!!statusCategories && statusCategories.length > 0) {
    postData["jobStatuses"] = mapJobStatuses(
      statusCategories,
      isFromAssignedColumn
    );
  }
  if (!!searchCriteria?.trim()) {
    postData["searchByField"] = "unit_name";
    postData["searchCriteria"] = searchCriteria;
  }

  if (!!categoryIds) {
    if (
      !isAllWorkOrderSelected &&
      categoryLists.length !== categoryIds.length
    ) {
      postData["categoryIds"] = categoryIds;
    } else if (
      isAllWorkOrderSelected &&
      categoryLists.length !== categoryIds.length
    ) {
      postData["isAllWorkOrderSelected"] = true;
    }
  }

  if (!!dateCategories && dateCategories.length > 0) {
    const dateCategory = dateCategories?.[0];

    if (dateCategory !== DEFAULT_DATE) {
      const day: number = parseInt(dateCategory);
      const endDate: Date = getNextDate(getCurrentDateLastTime());
      const startDate: Date = getPreviousDate(getPreviousDateByDay(day));

      const createdRange = {
        start: startDate.toUTCString(),
        end: endDate.toUTCString(),
      };
      postData["createdRange"] = createdRange;
    }
  }

  if (!!pageSize) {
    postData["pageSize"] = pageSize;
  }
  if (offset !== undefined) {
    postData["offset"] = offset;
  }

  return postData;
};

const getPropertyUnits = async (propertyId: string) => {
  const url = GET_PROPERTIES_UNITS.replace(":propertyId", propertyId);
  return await apiGet({
    apiPath: `${url}`,
  });
};

// get properties
const getProperties = async () => {
  return await apiGet({
    apiPath: GET_PROPERTIES,
  });
};

// get jobs
const findJobs = async (
  propertyIds: string[] | string
): Promise<IJobsProps<Job>> => {
  return await apiPost({
    apiPath: FIND_JOBS,
    data: {
      propertyIds: propertyIds,
      jobStatuses: [
        JobStatusMap.ASSIGNED,
        JobStatusMap.UNASSIGNED,
        JobStatusMap.IN_PROGRESS,
        JobStatusMap.ON_HOLD,
      ],
      sortByField: "created_time",
      sortByOrder: "DESC",
    },
  });
};

//get job
const findJobById = async (id: string): Promise<Job> => {
  return await apiGet({ apiPath: `${GET_JOB_ITEM}/${id}` });
};

// get pro users
const getProUsers = async (propertyIds: string | string[]) => {
  return await apiPost({
    apiPath: `${POST_PRO_USERS}`,
    data: { propertyIds },
  });
};

// get pro users for multi property
export const getProUsersForBoard = async (
  propertyIds: string | string[]
): Promise<ProUserProp[]> => {
  return new Promise(async (resolve, reject) => {
    let prosResponse: ProUserProp[] = [];
    try {
      prosResponse = await apiPost({
        apiPath: `${POST_PRO_USERS}`,
        data: { propertyIds },
      });
      let pros: ProUserProp[] = [];
      for (let i = 0; i < prosResponse.length; i++) {
        const pro: ProUserProp = prosResponse[i];
        const userIndex: number = findInArray(pros, pro.id, "id");
        if (userIndex > -1) {
          const propertyIds: string | string[] = pros[userIndex].propertyId;
          if (
            typeof propertyIds === "string" &&
            typeof pro.propertyId === "string"
          ) {
            pros[userIndex].propertyId = [propertyIds, pro.propertyId];
          } else if (
            typeof propertyIds !== "string" &&
            typeof pro.propertyId === "string"
          ) {
            pros[userIndex].propertyId = [...propertyIds, pro.propertyId];
          }
        } else {
          pros.push(pro);
        }
      }

      pros.sort(sortProUserByName);
      resolve(pros);
    } catch (error) {
      reject(error);
    }
  });
};
// find jobs pro users
const findJobsPro = async ({
  propertyIds,
  proAccountId,
  categoryIds,
  dateCategories,
  statusCategories,
}: {
  propertyIds: string | string[];
  proAccountId?: string;
  categoryIds?: string[];
  dateCategories?: string[];
  statusCategories?: string[];
}): Promise<IJobsProps<Job>> => {
  const accountId = !!proAccountId ? proAccountId : getUserAccountId();
  let postData: { [key: string]: any } = {
    jobStatuses: [
      JobStatusMap.ASSIGNED,
      JobStatusMap.IN_PROGRESS,
      JobStatusMap.ON_HOLD,
    ],
    sortByField: "created_time",
    sortByOrder: "DESC",
  };

  postData = addSearchCriterias({
    categoryIds,
    dateCategories,
    statusCategories,
    pageSize: 2000000,
    offset: 0,
    postData,
    propertyIds,
    isFromAssignedColumn: true,
  });
  return new Promise(async (resolve, reject) => {
    try {
      const jobsResponse: IJobsProps<Job> = await apiPost({
        apiPath: `${FIND_JOBS_PRO}/${accountId}`,
        data: postData,
      });
      let records = jobsResponse.records;
      records = records.filter(
        (job: Job) => job?.jobAssignment?.providerId === accountId
      );

      const assignedJobs: IJobsProps<Job> = {
        ...jobsResponse,
        records: records,
      };
      resolve(assignedJobs);
    } catch (error) {
      reject(error);
    }
  });
};

// find pro completed job
const getCompletedJobsPro = async ({
  propertyIds,
  pageSize = 20,
  offset = 0,
  searchCriteria = "",
  categoryIds,
  statusCategories,
  dateCategories,
}: {
  propertyIds: string | string[];
  pageSize?: number;
  offset?: number;
  searchCriteria?: string;
  categoryIds?: string[];
  dateCategories?: string[];
  statusCategories?: string[];
}) => {
  const accountId = getUserAccountId();

  let postData: { [key: string]: any } = {
    jobStatuses: [
      JobStatusMap.DONE,
      JobStatusMap.CLOSED,
      JobStatusMap.CANCELLED,
    ],
    sortByField: "last_updated_time",
    sortByOrder: "DESC",
  };

  postData = addSearchCriterias({
    categoryIds,
    dateCategories,
    statusCategories,
    postData,
    searchCriteria,
    propertyIds,
    offset: offset,
    pageSize: pageSize,
  });

  return await apiPost({
    apiPath: `${FIND_JOBS_PRO}/${accountId}`,
    data: postData,
  });
};

// get completed jobs
const getCompletedJobs = async ({
  propertyIds,
  pageSize = 20,
  offset = 0,
  searchCriteria = "",
  categoryIds,
  dateCategories,
  statusCategories,
}: {
  propertyIds: string | string[];
  pageSize?: number;
  offset?: number;
  searchCriteria?: string;
  categoryIds?: string[];
  dateCategories?: string[];
  statusCategories?: string[];
}) => {
  let postData: { [key: string]: any } = {
    jobStatuses: [
      JobStatusMap.DONE,
      JobStatusMap.CLOSED,
      JobStatusMap.CANCELLED,
    ],
    sortByField: "last_updated_time",
    sortByOrder: "DESC",
  };
  postData = addSearchCriterias({
    categoryIds,
    dateCategories,
    statusCategories,
    postData,
    searchCriteria,
    offset: offset,
    pageSize: pageSize,
    propertyIds: propertyIds,
  });
  return await apiPost({
    apiPath: FIND_JOBS,
    data: postData,
  });
};

// get jobs
const findUnassignedJobs = async ({
  propertyIds,
  categoryIds,
  dateCategories,
  statusCategories,
}: {
  propertyIds: string[] | string;
  categoryIds?: string[];
  dateCategories?: string[];
  statusCategories?: string[];
}): Promise<IJobsProps<Job>> => {
  let postData: { [key: string]: any } = {
    jobStatuses: [
      JobStatusMap.ASSIGNED,
      JobStatusMap.UNASSIGNED,
      JobStatusMap.IN_PROGRESS,
      JobStatusMap.ON_HOLD,
    ],
    sortByField: "created_time",
    sortByOrder: "DESC",
  };

  postData = addSearchCriterias({
    categoryIds,
    dateCategories,
    statusCategories,
    postData,
    propertyIds: propertyIds,
  });

  return await apiPost({
    apiPath: FIND_JOBS,
    data: postData,
  });
};

const getUnassignedJobs = async ({
  propertyIds,
  categoryIds,
  dateCategories,
  statusCategories,
  proUsers = store.getState().kanbanBoardDetails.proUsers,
}: {
  propertyIds: string[] | string;
  categoryIds?: string[];
  dateCategories?: string[];
  statusCategories?: string[];
  proUsers?: ProUserProp[];
}): Promise<IJobsProps<Job>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const proUserIds: string[] = proUsers.map((pro) => pro.id);
      const jobsResponse: IJobsProps<Job> = await findUnassignedJobs({
        propertyIds: propertyIds,
        categoryIds: categoryIds,
        dateCategories: dateCategories,
        statusCategories,
      });
      let records = jobsResponse.records.filter(
        (job: Job) =>
          job?.jobStatus === "UNASSIGNED" ||
          job?.jobAssignment?.providerName === MAINTENANCE_RESPROP ||
          job?.jobAssignment?.providerId === "" ||
          (job.jobAssignment?.providerId !== "" &&
            job.jobAssignment?.providerId !== undefined &&
            !proUserIds.includes(job.jobAssignment?.providerId))
      );

      const unassignedJobs: IJobsProps<Job> = {
        ...jobsResponse,
        records: records,
      };
      resolve(unassignedJobs);
    } catch (error) {
      reject(error);
    }
  });
};

const getCategories = async (): Promise<{ categories: Category[] }> => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiGet({ apiPath: `${GET_CATEGORIES}` });
      resolve({ categories: data || [] });
    } catch (error) {
      reject(error);
    }
  });
};

const kanBanServices = {
  getUnassignedJobs,
  findUnassignedJobs,
  getProperties,
  getPropertyUnits,
  findJobs,
  getCompletedJobsPro,
  findJobsPro,
  getProUsersForBoard,
  findJobById,
  getProUsers,
  getCompletedJobs,
  getCategories,
};

export default kanBanServices;
